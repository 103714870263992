import axios from 'axios';
import qs from 'query-string';
import Bugsnag from '@bugsnag/js';

// List of regional endpoints
const REGIONAL_ENDPOINTS = [
  '^/accounts/balance$',
  '^/accounts/([-a-zA-Z0-9]*)/message-balance$', // get account message balance
  '^/accounts/sender-ids$', // get account sender ids
  '^/accounts/sender-ids/validate$', // validate sender id
  '/chats/logs',
  '/chats/logs/export',
  '/chats/summary',
  '/chats/reports',
  '/chats/reports/export',
  '^/files/upload-url$',
  '^/websender/uploads/([-a-zA-Z0-9]*)/process$', // /websender/uploads/:id/process
  '^/websender/sms/send-mcs$', // /websender/sms/send-mcs
  '^/websender/sms/send-for-approval$', // /websender/sms/send-for-approval
  '^/websender/sender-id/validate$', // /websender/sender-id/validate
  '^/websender/sender-ids$', // /websender/sender-ids
  '/websender/signed-invalid-url', // /websender/signed-invalid-url
  '^/mcs/chatapps/send$', // /mcs/chatapps/send
  '^/groups$', // /groups
  '^/groups/get-totals-mcs$', // /groups/get-totals-mcs
  '^v1/contacts$', // /contacts
  '^campaigns$', // /campaigns
  '^campaigns/([-a-zA-Z0-9]*)$', // /campaigns/:campaignId
  '^campaigns/([-a-zA-Z0-9]*)/download', // /campaigns/:campaignId
  '^/campaigns/action/download$', // /campaigns/action/download
  '^/campaigns/action/cancel$', // /campaigns/action/cancel
  '^/campaigns/action/reject$', // /campaigns/action/reject
  '^/campaigns/action/approve$', // /campaigns/action/approve
  '^/mcs/voice/send$', // voice
  '^/contacts-management/*', // contact-management
  '^/contacts/*',
  '^campaigns/sms$',
  '^campaigns/chatapps$',
  '^campaigns/*',
  '^/campaigns',
  '^/campaigns/*',
  '^/survey-list/responses$', // /survey-list/responses
  '^/survey-list/summary$', // /survey-list/summary
  '^/short-urls$', // /short-urls
  '^/short-urls/summary$', // /short-urls/summary
  '^/short-urls/action/download$', // /short-urls/action/download
  '^/reports/sms/logs/export$', // /reports/sms/logs/export
  '^/contacts$', // /contacts
  '^/contacts/add-to-group$', // /contacts/add-to-group
  '^/contacts/([-a-zA-Z0-9]*)$', // /contacts/:msisdn
  '^/contacts/([-a-zA-Z0-9]*)/delete', // /contacts/:msisdn/delete
  '^/groups$', // /groups
  '^/groups/([-a-zA-Z0-9]*)$', // /groups/:groupId
  '^/groups/([-a-zA-Z0-9]*)/delete', // /groups/:groupId/delete
  '^/logs$', // /logs
  '^/files$', // /files
  '/files/upload-url',
  '^/files/([-a-zA-Z0-9]*)/actions/download', // /files/:fileId/download
  '^/files/([-a-zA-Z0-9]*)/update', // /files/:fileId/update
  '^/reports$', // /reports
  '^/reports/download$', // /reports/download
  '^/reports/operators/summary$', // /reports/operators/summary
  '^/reports/([-a-zA-Z0-9]*)/conversion$', // /reports/:userId/conversion
  '^/reports/countries|operators(?:&?[^=&]*=[^=&]*)*$', // /api/v1/reports/countries?dateFrom=2021-10-13&dateTo=2021-10-20&utcOffset=0
  '^/reports/traffic/config', // /reports/traffic/config
  '^/contacts-management/*', // contact-management
];

const DEFAULT_REGION_ID = 0;
const API_CONFIG = {
  v1: {
    0: `${process.env.VUE_APP_API_URL}/api/v1`,
    1: `${process.env.VUE_APP_API_URL_ID}/api/v1`,
    2: `${process.env.VUE_APP_API_URL_UK}/api/v1`,
    3: `${process.env.VUE_APP_API_URL_US}/api/v1`,
  },
  v2: {
    0: `${process.env.VUE_APP_API_URL}/api/v2`,
    1: `${process.env.VUE_APP_API_URL_ID}/api/v2`,
    2: `${process.env.VUE_APP_API_URL_UK}/api/v2`,
    3: `${process.env.VUE_APP_API_URL_US}/api/v2`,
  },
  mobileSdk: {
    0: `${process.env.MOBILE_SDK_API_URL || 'https://mobileidentity.8x8pilot.com'}/api`,
  },
  campaign: {
    0: `${process.env.VUE_APP_CAMPAIGN_API_URL}/api/v1`,
    1: `${process.env.VUE_APP_CAMPAIGN_API_URL_ID}/api/v1`,
    2: `${process.env.VUE_APP_CAMPAIGN_API_URL_UK}/api/v1`,
    3: `${process.env.VUE_APP_CAMPAIGN_API_URL_US}/api/v1`,
  },
  contacts: {
    0: `${process.env.VUE_APP_CONTACTS_API_URL}/api/v1`,
    1: `${process.env.VUE_APP_CONTACTS_API_URL_ID}/api/v1`,
    2: `${process.env.VUE_APP_CONTACTS_API_URL_UK}/api/v1`,
    3: `${process.env.VUE_APP_CONTACTS_API_URL_US}/api/v1`,
  },
  voice: {
    0: `${process.env.VUE_APP_VOICE_API_URL}/api/v1`,
  },
  sms: {
    0: `${process.env.VUE_APP_SMS_API_URL}/api/v1`,
    1: `${process.env.VUE_APP_SMS_API_URL_ID}/api/v1`,
    2: `${process.env.VUE_APP_SMS_API_URL_UK}/api/v1`,
    3: `${process.env.VUE_APP_SMS_API_URL_US}/api/v1`,
  }
}

// Decode jwt token
const decodeJwt = (token) => {
  if (!token) {
    return 0;
  }

  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

    const decodedData = JSON.parse(jsonPayload);
    return decodedData;
  } catch (err) {
    console.error('Failed to validate region');
    return 0;
  }
};

const responseErrorConfig = err => Promise.reject((err && err.response) || {});
const responseSuccessConfig = (response) => {
  if (response.headers && response.headers.authorization) {
    localStorage.setItem('WWW-Authenticate', response.headers.authorization);
  }
  return response;
}
const requestErrorConfig = err => Promise.reject(err);
const requestSuccessConfig = (c, api = 'v1') => {
  const config = c;
  const { url } = config;
  try {
    const token = localStorage.getItem('WWW-Authenticate');

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // eslint-disable-line
    }

    const regionalApis = ['campaign', 'contacts'];
    const noQueryUrl = url.split('?')[0];
    const endpoint = noQueryUrl.startsWith('/') ? noQueryUrl : `/${noQueryUrl}`;
    const isRegional = REGIONAL_ENDPOINTS.find(apiRegExp => new RegExp(apiRegExp).test(endpoint)) || regionalApis.includes(api);

    if (isRegional) {
      // Decode jwt and determine region based on RegionId
      const decodedData = decodeJwt(token);
      let regionId = '';

      if (decodedData && Object.keys(decodedData).includes('RegionId')) {
        const { RegionId: region } = decodedData;
        regionId = region;
      }

      config.baseURL = API_CONFIG[api][regionId];
    } else {
      config.baseURL = API_CONFIG[api][DEFAULT_REGION_ID];
    }

    return config;
  } catch (error) {
    if (Bugsnag) {
      Bugsnag.notify(error);
    }
  }
  return 0;
};

// Create instances for Connect v1, v2, and mobile sdk APIs
const httpv1 = axios.create({ baseURL: API_CONFIG['v1'][DEFAULT_REGION_ID], });
const httpv2 = axios.create({ baseURL: API_CONFIG['v2'][DEFAULT_REGION_ID], });
const httpSdkApi = axios.create({ baseURL: API_CONFIG['mobileSdk'][DEFAULT_REGION_ID], });

httpv1.interceptors.response.use(responseSuccessConfig, responseErrorConfig);
httpv1.interceptors.request.use(config => requestSuccessConfig(config, 'v1'), requestErrorConfig);
httpv2.interceptors.response.use(responseSuccessConfig, responseErrorConfig);
httpv2.interceptors.request.use(config => requestSuccessConfig(config, 'v2'), requestErrorConfig);
httpSdkApi.interceptors.response.use(responseSuccessConfig, responseErrorConfig);
httpSdkApi.interceptors.request.use(config => requestSuccessConfig(config, 'v2'), requestErrorConfig);

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */

const generateMethods = httpversion => ({
  request: options => httpversion(options),
  get: (url, params = {}) => httpversion.get(url, {
    params: {
      ...params,
    },
    paramsSerializer(p) {
      return qs.stringify(p, { indices: false });
    },
  }),
  post: (url, data = {}, config = {}) => httpversion.post(url, data, config),
  put: (url, data = {}) => httpversion({ method: 'put', url, data }),
  delete: (url, data = {}) => httpversion({ method: 'delete', url, data }),
  patch: (url, data = {}) => httpversion({ method: 'patch', url, data }),
});

export default {
  v1: generateMethods(httpv1),
  v2: generateMethods(httpv2),
  sdk: generateMethods(httpSdkApi),
  post: (url, data = {}, config = {}) => axios.post(url, data, config),
  config: {
    apiConfig: API_CONFIG,
    regionalEndpoints: REGIONAL_ENDPOINTS,
    requestSuccessConfig,
    responseSuccessConfig,
  }
};
