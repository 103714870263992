//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModulePermission from './partials/ModulePermission.vue';
import InviteUser from './partials/InviteUser.vue';
import SSOConfiguration from './partials/SSOConfiguration.vue';

export default {
  name: 'UserModal',

  components: {
    ModulePermission,
    InviteUser,
    SSOConfiguration,
  },

  props: {
    modalState: {
      type: Object,
      default: () => ({
        user: {},
        name: 'ModulePermission',
        visible: false,
      }),
    },

    clearFilters: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      isVisible: false,
      showClose: true,
    };
  },

  methods: {
    toggleModal(bool) {
      this.modalState.visible = bool;
      this.isVisible = bool;
      this.modalState.name = bool ? this.modalState.name : '';
    },

    toggleShowClose(bool) {
      this.showClose = bool;
    },
  },
};
