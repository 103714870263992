import moment from 'moment';
import momentDuration from 'moment-duration-format';

momentDuration(moment);

export default {
  methods: {
    // Time difference between current time and given time
    timeFromNow(datetime) {
      return moment(datetime).fromNow();
    },

    // Format date
    formatDate(datetime, option) {
      const defaultOption = {
        format: 'D MMM YYYY',
        showCurrYear: true,
        utcOffset: 0,
      };
      option = { ...defaultOption, ...option }; // eslint-disable-line
      option.format = !option.showCurrYear && moment(datetime).format('YYYY') === moment().format('YYYY') // eslint-disable-line
        ? option.format.replace(/[yY]/g, '').trim()
        : option.format;
      return moment.utc(datetime).utcOffset(option.utcOffset, false).format(option.format);
    },

    // Relative to Calendar
    timeFromCalendar(datetime) {
      return moment(datetime).calendar(null, {
        sameDay: '[Today], hh:mm A',
        lastDay: '[Yesterday], hh:mm A',
        nextDay: '[Tomorrow], hh:mm A',
        lastWeek: '[Last] ddd, hh:mm A',
        nextWeek: 'ddd, hh:mm A',
        sameElse() {
          return this.year() === moment().year() ? 'D MMM, hh:mm A' : 'D MMM YYYY, hh:mm A';
        },
      });
    },

    // Format duration: param seconds
    FormatDuration(s) {
      const format = s >= 3600 ? 'hh:mm:ss' : 'mm:ss';
      return moment.duration(s, 'second').format(format, { trim: false });
    },

    // Format labelled duration : param seconds and custom label name
    FormatLabelledDuration(s, unit = {}) {
      unit = { // eslint-disable-line
        h: 'hrs', m: 'min', s: 'sec', ...unit,
      };
      return moment.duration(s, 'second').format(`h [${unit.h}], m [${unit.m}], s [${unit.s}]`);
    },

    formatSecs(s) {
      if (!s) return '-';

      let v = '';
      if (s < 60) {
        const sw = (s > 1) ? 'secs' : 'sec';
        v = `${s} ${sw}`;
      }

      if (s > 59 && s < 3600) {
        const min = Math.floor(s / 60);
        const mw = (min > 1) ? 'mins' : 'min';
        v = `${min} ${mw}`;
      }

      if (s > 3599) {
        const h = Math.floor(s / 3600);
        const hw = Math.floor((s - (h * 3600)) / 60);
        v = `${h}:${hw}`;
      }

      return v;
    },

    getDateHeader(tz, text) {
      return `${text || this.$t('column_labels.date')} ${tz}`;
    },
  },
};
