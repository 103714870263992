
import _ from 'lodash';

const prettifyLabel = (string) => {
  if (!string) return '';

  let st = '';

  st = string
    .replace(/_v2/, '')
    .replace(/\./g, ' ')
    .replace(/CA/, 'Messaging Apps')
    .replace(/VI/, 'Video')
    .replace(/VO/, 'Voice');

  st = _.startCase(st);

  // remove SMS prefix since these are general roles
  if (string.match(/SMS.CampaignApprover_v2|SMS.NeedsApproval_v2/i)) {
    st = st.replace(/SMS/i, '');
  }

  return st;
};

const concatModules = (modules) => {
  const m = modules.slice(0).map((v) => {
    const x = prettifyLabel(v);

    return x;
  });

  return m.join(', ');
};

export default {
  prettifyLabel,
  concatModules,
};
