/* eslint-disable func-names */

import Bugsnag from '@bugsnag/js';

import englishData from '../../locale/lang/en.json';

import http from '../../utils/http';

const genericErrorMsgs = [
  'User suspended',
  "Sorry, you don't have access to this application",
  'Username or password is incorrect',
  'Password reset required',
  'Invalid Authenticator app code',
  'Email address not found',
  'Approval deadline expired',
  'Campaign status is not for approval',
  'Contact already exists',
  'Group name exists. Please choose a different name',
  'Disposable emails are not allowed',
  'Email address already exists',
  'Template name already exists',
  'User not found',
  'Incorrect password',
  'Mobile number not allowed for free credits',
  'Template name should only contain lowercase letters, numbers and underscores',
  'There is already English content for this template. You can create a new template and try again',
  "Direct links to WhatsApp aren't allowed for buttons",
  'date range values are invalid',
  'At least 1 email address is required',
  'At least 1 subaccount is required',
  'Phone number is required',
  'Invalid date range',
  'Template name should only contain lowercase letters, numbers and underscores',
  'There is already an English content for this template. Please create a new template and try again',
  "Direct links to WhatsApp aren't allowed for buttons",
  'Your session has expired',
];

const getErrorMsg = (ctx, err, options = {}) => {
  const error = (err && err.data) || err;
  let statusCode = 0;
  let main = ctx.$t('errors.default');
  let info = ctx.$t('errors.unexpected_error');
  let contactUs = '';
  const { errorId } = error;
  let errors = [];
  const { apiErrors } = ctx.$data.englishData;
  let originalError = 'Unexpected error';

  if (error.code) {
    statusCode = parseInt(error.statusCode, 10);
  }

  if (error.statusCode) {
    statusCode = parseInt(error.statusCode, 10);
  }

  if (error.status) {
    statusCode = parseInt(error.status, 10);
  }

  if (error.body && error.body.statusCode) {
    statusCode = parseInt(error.body.statusCode, 10);
  }

  if (error.body && error.body.code) {
    statusCode = parseInt(error.body.code, 10);
  }

  if (error.response && error.response.statusCode) {
    statusCode = parseInt(error.response.statusCode, 10);
  }

  if (error.response && error.response.status) {
    statusCode = parseInt(error.response.status, 10);
  }

  if (err.status) {
    statusCode = err.status;
  }

  if (error.Code) {
    statusCode = parseInt(err.status, 10);
  }

  if (error.Message) {
    const enTranslation = apiErrors[error.Message];
    originalError = error.Message;
    if (enTranslation) {
      info = ctx.$t(`apiErrors.${error.Message}`);
      errors.push(info);
    } else {
      info = error.Message;
      errors.push(info);
    }
  }


  if (error.error) {
    // should also be available in all languages
    // but only checking english since it's the default
    // if it's not there, we fix the json file to add the code.
    if (typeof error.error === 'string' || error.error instanceof String) { // For parsing API V1 error responses
      const enTranslation = apiErrors[error.error];
      const isUnprocessable88 = error.error.includes('unprocessable_88');
      if (isUnprocessable88) {
        const index = error.error.charAt(16);
        info = ctx.$t(`changePasswordError.error_00${index}`);
        originalError = error.error;
        errors.push(info);
      } else if (enTranslation) {
        info = ctx.$t(`apiErrors.${error.error}`);
        originalError = error.error;

        if (error.details && statusCode === 422) {
          errors = error.details.map(({ field, message }) => `${field} (${message})`);
        } else {
          errors.push(info);
        }
      } else if (error.error.message) {
        info = error.error.message;
        originalError = error.error.message;
        errors.push(info);
      } else {
        info = error.error;
        originalError = error.error;
        errors.push(info);
      }
    } else if (error.error.message) { // For parsing of API V2 error response
      const enTranslation = apiErrors[error.error.message];
      originalError = error.error.message;
      if (enTranslation) {
        info = ctx.$t(`apiErrors.${error.error.message}`);
        errors.push(info);
      } else {
        info = error.error.message;
        errors.push(info);
      }
    }
  }

  if (error.message) {
    info = error.message;
    originalError = error.message;
    errors.push(info);
  }

  if (error.body && error.body.error) {
    info = error.body.error;
    originalError = error.body.error;
    errors.push(info);
  }

  if (error.body && error.body.message) {
    info = error.body.message;
    originalError = error.body.message;
    errors.push(info);
  }

  if (error.response && error.response.error) {
    info = error.response.error;
    originalError = error.response.error;
    error.push(info);
  }

  if (error.response && error.response.data && error.response.data.message) {
    info = error.response.data.message;
    originalError = error.response.data.message;
    errors.push(info);
  }


  switch (statusCode) {
  case 400: {
    main = ctx.$t('errors.400');
    break;
  }

  case 401: {
    main = ctx.$t('errors.401');
    break;
  }

  case 422: {
    main = ctx.$t('errors.invalid');
    break;
  }

  case 403: {
    main = ctx.$t('errors.403');
    break;
  }

  case 404: {
    main = ctx.$t('errors.404');
    break;
  }

  case 503:
  case 501:
  case 502:
  case 500: {
    main = ctx.$t('errors.500');
    break;
  }

  default:
  }


  if ([0, 500].includes(statusCode)) {
    contactUs = ctx.$t('errors.try_again');
  }

  if (info.match(/access forbidden/i)) {
    main = ctx.$t('errors.403');
  }

  // override error messages (401 errors, cors, etc)
  // that contains "Unexpected token < in JSON at position"
  // as we don't want it to be shown to the user
  if (info.match(/Unexpected token/i)) {
    info = ctx.$t('errors.unexpected_error');
    errors.push(info);
  }

  if (options.useMessage) {
    main = options.useMessage;
  }

  return {
    main,
    contactUs,
    statusCode,
    info,
    errors,
    errorId,
    originalError,
  };
};

/**
 * Checks for 401 errors, expired tokens, invalid access, and/or invalid credentials.
 * New condition: Add checking for 401 errors from APIs that are read-only during impersonation.
 */
const isJWTExpired = (ctx, msg) => ((msg.statusCode && msg.statusCode === 401) || msg.info.match(/unauthorized|jwt expired|invalid credentials|invalid token/i))
  && msg.originalError !== 'impersonation_forbidden_error';

const hasMatchedGenericMessages = (errorMsg = '') => genericErrorMsgs.some(v => v.toLowerCase() === errorMsg.toLowerCase());

const removeAuthInLocalStorage = () => {
  localStorage.removeItem('WWW-Authenticate');
  localStorage.removeItem('cpv3Impersonate');
  localStorage.removeItem('CPV3_User');
  localStorage.removeItem('passwordResetSkipped');
  localStorage.removeItem('user_country');
  sessionStorage.removeItem('sessionHideSuggestEnableTwoFa');
};


const errorHandler = {
  install(Vue) {
    Vue.mixin({
      data() {
        return {
          doneLogout: false,
          englishData,
          errorInfoVisible: true,
        };
      },

      methods: {
        // eslint-disable-next-line no-unused-vars
        $reportError(error, msg) {
          // eslint-disable-next-line no-console
          console.error(error);
          Bugsnag.notify(new Error(msg.info));
          // OLD Implementation
          // if (window.Bugsnag) {
          //   window.Bugsnag.notify(new Error(msg.info), null, (err, event) => {
          //     if (err) {
          //       // eslint-disable-next-line no-console
          //       console.info(`Failed to send report because of:\n${ err.stack}`);
          //     } else {
          //       // eslint-disable-next-line no-console
          //       console.info(`Successfully sent report "${ event.errors[0].errorMessage }"`);
          //     }
          //   });
          // }
        },

        // eslint-disable-next-line no-unused-vars
        $showError(ctx, err, options = {}) {
          let error = err;
          // eslint-disable-next-line no-console

          if (typeof error !== 'object') {
            error = new Error('Unexpected Error');
          }

          const { reloadUrl = null, hideContactUs = false } = options;
          const msg = getErrorMsg(ctx, error, options);

          this.$reportError(error, msg);

          const h = ctx.$createElement;

          const isHideErrorInfo = isJWTExpired(ctx, msg)
            || hasMatchedGenericMessages(msg.originalError);

          const uiDisplay = isHideErrorInfo ? 'hidden' : 'block';

          let info = [
            h('span', null, msg.info),
          ];

          const isPasswordExpired = msg.info.includes('Password expired');
          if (isPasswordExpired) {
            const expiredError = 'Your password has expired. To continue using your account, set a new password on the';
            info = [
              h('span', null, expiredError),
              h('a', {
                class: 'no-underline text-blue',
                attrs: {
                  href: '/login/forgot-password',
                  target: '_blank',
                },
              }, ' password reset page'),
            ];
          }

          ctx.$msgbox({
            title: msg.originalError === 'impersonation_forbidden_error' ? ctx.$t('errors.read_only') : ctx.$t('errors.oh_snap'),
            message: h('div', { id: 'error-dialog' }, [
              h('div', { class: 'flex mb-3' }, [
                h('div', { class: 'flex flex-col justify-center w-1/5 text-center' }, [
                  h('span', { class: 'block text-red el-icon-error text-4xl align-middle' }),
                ]),
                h('div', { class: 'w-4/5 px-3' }, [
                  h('div', { class: `${uiDisplay} text-md text-gray-dark font-medium` }, msg.main),
                  h('div', { class: 'text-md text-red-light font-medium mt-3' }, info),
                  h('div', { class: `text-xs text-grey mt-2 ${msg.errorId ? 'block' : 'hidden'} ${uiDisplay}` }, `ERROR ID: ${msg.errorId}`),
                  h('div', { class: `text-md mt-3 text-gray-dark font-medium ${msg.contactUs && !isHideErrorInfo && !hideContactUs ? 'block' : 'hidden'}` }, [
                    h('span', null, msg.contactUs),
                    h('a', {
                      class: 'no-underline text-blue',
                      attrs: {
                        href: 'mailto:cpaas-support@8x8.com',
                        target: '_blank',
                      },
                    }, ' cpaas-support@8x8.com'),
                  ]),
                ]),
              ]),
            ]),
            showCancelButton: false,
            confirmButtonText: isJWTExpired(ctx, msg) ? ctx.$t('user.go_back_login') : ctx.$t('actions.close'),
          }).then(() => {
            if (isJWTExpired(ctx, msg) && !this.doneLogout) {
              this.doneLogout = true;
              this.$logout(ctx, error, options);
            }

            if (reloadUrl) {
              window.location = reloadUrl;
            }
          })
            .catch(() => {
              if (isJWTExpired(ctx, msg) && !this.doneLogout) {
                this.doneLogout = true;
                this.$logout(ctx, error, options);
              }
            });
        },

        async $logout(ctx, error, options) {
          if (!localStorage.getItem('WWW-Authenticate')) {
            removeAuthInLocalStorage();
            if (!options.isLogin) window.location = '/';
            return;
          }

          try {
            await http.v1.post('auth/logout');

            removeAuthInLocalStorage();

            if (this.$telemetry) {
              this.$telemetry.teardownTelemetry();
            }

            if (!options.isLogin) window.location = '/';
            return;
          } catch (e) {
            let status = e.status && parseInt(e.status, 10);

            if (e.statusCode) {
              status = parseInt(e.statusCode, 10);
            }


            if (status === 401) {
              removeAuthInLocalStorage();
            }

            if (!options.isLogin) window.location = '/';
          }
        },
      },
    });

    const ctx = new Vue();

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$showError = function (self, err) {
      ctx.$showError(self, err);
    };
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$displayError = function (self, err) {
      ctx.$showError(self, err);
    };
  },
};

export default errorHandler;
