import actions from './actions';

export default {
  namespaced: true,

  state: {
    users: [],
    filteredUsers: [],
    paginated: [],
    filters: { status: 'A' },
    pageNo: 1,
    pageSize: 10,
    search: '',
    modules: [],
    subAccounts: [],
    currUser: {},
    reportsConfig: {},
    loading: true,
  },

  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload; //eslint-disable-line
    },

    SET_USERS(state, payload) {
      state.users = payload; //eslint-disable-line
    },

    SET_FILTERED_USERS(state) {
      const { status } = state.filters;
      // status = 'A'(ACTIVE)
      // status = 'I'(INVITED)
      // status = 'B'(DEACTIVATEd)
      let filteredUsers = state.users.filter(user => user.UserStatus === status); //eslint-disable-line

      if (state.search) {
        const filters = ['Firstname', 'Lastname', 'Login'];
        // eslint-disable-next-line no-param-reassign
        filteredUsers = filteredUsers
          .filter(row => filters.some((k) => {
            const s = row[k];

            if (s) {
              return s
                .toLowerCase()
                .includes(state.search.toLowerCase());
            }
            return 0;
          }));
      }

      // admins should be on top over users
      const admins = filteredUsers.filter(u => u.AccessLevel === 'A');
      const nonAdmins = filteredUsers.filter(u => u.AccessLevel === 'U');

      // eslint-disable-next-line no-param-reassign
      state.filteredUsers = [].concat(admins).concat(nonAdmins);

      const start = state.pageNo * state.pageSize - state.pageSize;
      const end = start + state.pageSize;
      state.paginated = state.filteredUsers.slice(start, end); //eslint-disable-line
    },

    SET_FILTERS(state, payload) {
      state.filters = { ...state.filters, ...payload }; //eslint-disable-line
    },

    SET_PAGE_NO(state, page) {
      state.pageNo = page; //eslint-disable-line
    },

    SET_PAGE_SIZE(state, pageSize) {
      state.pageSize = pageSize; //eslint-disable-line
    },

    SET_SEARCH(state, search) {
      state.search = search; //eslint-disable-line
    },

    RESET_FILTERS(state) {
      state.filters = { status: 'A' }; //eslint-disable-line
      state.pageNo =  1; //eslint-disable-line
      state.pageSize = 10; //eslint-disable-line
      state.search = ''; //eslint-disable-line
      state.filteredUsers = []; //eslint-disable-line
      state.paginated = []; //eslint-disable-line
    },

    SET_ALL_MODULES(state, payload) {
      state.modules = payload; //eslint-disable-line
    },
    SET_CURRENT_USER(state, payload) {
      state.currUser = payload; //eslint-disable-line
    },
    SET_SUB_ACCOUNTS(state, payload) {
      state.subAccounts = payload; //eslint-disable-line
    },
    SET_LOADING_SEND(state, payload) {
      state.loadingSend = payload; //eslint-disable-line
    },
    UPDATE_REPORTS_CONFIG(state, payload) {
      state.reportsConfig = payload; // eslint-disable-line no-param-reassign
    },
  },

  getters: {
    getLoading(state) {
      return state.loading;
    },

    currentUser(state) {
      return state.currUser;
    },

    getFilters(state) {
      return state.filters;
    },

    getPageNo(state) {
      return state.pageNo;
    },

    getPaginated(state) {
      return state.paginated;
    },

    getPageSize(state) {
      return state.pageSize;
    },

    getUsers(state) {
      return state.users;
    },

    getFilteredUsers(state) {
      return state.filteredUsers;
    },

    getSearch(state) {
      return state.search;
    },

    getReportConfig(state) {
      return state.reportsConfig;
    },

    getAllModules(state) {
      return state.modules;
    },
  },

  actions,
};
