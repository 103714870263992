<template>
  <div class="user-management">
    <div class="bg-white u-container flex justify-between mt-5 mb-5 items-center">
      <div>
        <h1 class="text-xl text-color font-semibold page-title pt-2">
          {{ $t('user_management.title') }}
        </h1>
        <div
          v-if="userAuthData
            && (userAuthData.AccessLevel === 'A' || (userAuthData.AccessLevel === 'U' && userAuthData.Roles.includes('UserManagement.ManageSSOSettings_v2')) )"
          class="flex mt-3 items-center"
        >
          <div class="flex-auto mr-3">
            <div
              v-if="getConfig && getConfig.Enabled"
              class="text-sm p-1 border border-green-light rounded flex"
              :style="{padding: '0.4rem'}"
            >
              <div class="flex-shrink">
                <i
                  class="el-icon-success text-green align-middle mr-1"
                  :style="{ fontSize: '18px'}"
                />
              </div>
              <div class="flex-auto text-center">
                {{ $t('user_management.index[0]') }}
              </div>
            </div>
            <div
              v-else
              class="text-sm border border-red-light rounded flex"
              :style="{padding: '0.4rem'}"
            >
              <div class="flex-shrink">
                <i
                  class="el-icon-info text-gray-darker align-middle mr-1"
                  :style="{ fontSize: '18px'}"
                />
              </div>
              <div class="flex-auto text-center">
                {{ $t('user_management.index[1]') }}
              </div>
            </div>
          </div>
          <div class="mr-3">
            <el-button
              v-if="getConfig && getConfig.Enabled"
              :loading="actionLoading"
              :disabled="loading"
              @click="disable"
            >
              {{ $t('actions.disable') }}
            </el-button>
            <el-button
              v-else
              type="primary"
              :loading="actionLoading"
              :disabled="loading"
              @click="enable"
            >
              {{ $t('actions.enable') }}
            </el-button>
          </div>
          <div class="">
            <el-button
              type="primary"
              :disabled="loading"
              @click="openModal({ user: userAuthData, name: 'SSOConfiguration', type: 'update' })"
            >
              {{ $t('user_management.index[2]') }}
            </el-button>
          </div>
        </div>
      </div>
      <div class="flex mr-3 self-end">
        <div class="text-right">
          <el-switch
            v-if="userAuthData
              && (userAuthData.AccessLevel === 'A')"
            v-model="enforce2fa"
            class="mr-5"
            :inactive-text="$t('actions.enforce2fa')"
            :disabled="isImpersonation || (getConfig && getConfig.Enabled)"
            @change="enable2fa"
          />
          <!-- <el-button
            v-if="userAuthData
              && (userAuthData.AccessLevel === 'A' || (userAuthData.AccessLevel === 'U' && userAuthData.Roles.includes('UserManagement.ManageSSOSettings_v2')) )
              && (userAuthData.CustomerType === 'I'
              || userAuthData.CustomerType === 'E')
              && getConfig && !Object.keys(getConfig).length"
            type="secondary"
            :disabled="loading"
            @click.native="openModal({ user: userAuthData, name: 'SSOConfiguration', type: 'create' })"
          >
            {{ $t('user_management.index[3]') }}
          </el-button> -->
          <el-button
            v-if="userAuthData
              && (userAuthData.AccessLevel === 'A' || (userAuthData.AccessLevel === 'U' && userAuthData.Roles.includes('UserManagement.ManageUsers_v2')) )"
            type="primary"
            :disabled="loading || !Object.keys(userAuthData).length"
            @click.native="openModal({ name: 'InviteUser', user: userAuthData })"
          >
            {{ $t('user_management.index[4]') }}
          </el-button>
        </div>
      </div>
    </div>

    <div
      v-loading="getLoading"
      class="flex"
    >
      <div class="w-full pr-3 mb-5">
        <div class="border-grey-lighter border solid flex flex-col w-full relative rounded bg-grey-inputs">
          <div class="flex-shrink border-b border-grey-lighter">
            <div class="">
              <div class="flex px-3 py-2">
                <div class="w-3/5">
                  <el-input
                    v-model="searchInput"
                    class="w-full"
                    prefix-icon="icon-novo-search"
                    :disabled="userAuthData && userAuthData.AccessLevel === 'U' && !userAuthData.Roles.includes('UserManagement.ViewUsers_v2')"
                    :placeholder="$t('user_management.index[5]')"
                    @keyup.native="searchUser"
                  />
                </div>
                <div class="px-5 flex-auto flex items-center">
                  <el-badge
                    class="filter"
                    :value="filterCount"
                    type="warning"
                    :hidden="!filterCount"
                  >
                    <span
                      class="cursor-pointer icon-novo-filter text-2xl p-1 hover-icon"
                      @click="isMoreFilterVisible = !isMoreFilterVisible"
                    />
                  </el-badge>
                  <span
                    v-if="getPaginated.length"
                    class="ml-6 text-base"
                  >{{ getPaginated.length.toLocaleString() }} {{ $t('app_labels.results') }}</span>
                </div>
                <div class="flex-shrink px-4 border-grey-lighter border-l flex items-center">
                  <el-tooltip
                    :content="$t('actions.refresh')"
                    placement="top"
                  >
                    <span
                      class="icon-novo-refresh text-2xl cursor-pointer hover-icon"
                      @click="refresh"
                    />
                  </el-tooltip>
                </div>
              </div>
              <div
                v-if="isMoreFilterVisible"
                class="flex py-3 border-t border-grey-lighter px-3 justify-between"
              >
                <div
                  class="ml-2"
                >
                  <span class="text-xs block mb-2">{{ $t('column_labels.status') }}</span>
                  <el-select
                    v-model="userStatus"
                    :disabled="userAuthData && userAuthData.AccessLevel === 'U' && !userAuthData.Roles.includes('UserManagement.ViewUsers_v2')"
                    class="w-full"
                    :placeholder="$t('user_management.index[6]')"
                    size="small"
                    @change="selectStatus"
                  >
                    <el-option
                      v-for="item in statuses"
                      :key="item.status"
                      :label="item.name"
                      :value="item.status"
                    />
                  </el-select>
                </div>
                <div class="flex-shrink flex items-center justify-center px-4 py-2">
                  <a
                    href="#"
                    class="no-underline text-sm mt-1"
                    searchInput=""
                    @click.prevent="clearFilters()"
                  >{{ $t('actions.clear_all') }}</a>
                </div>
              </div>
            </div>
          </div>
          <UsersTable
            :auth="userAuthData"
            :cannot-view="userAuthData && userAuthData.AccessLevel === 'U' && !userAuthData.Roles.includes('UserManagement.ViewUsers_v2')"
            :users="getUsers"
            :filtered-users="getFilteredUsers"
            :page-no="getPageNo"
            :page-size="getPageSize"
            :search="getSearch"
            :filters="getFilters"
            :paginated="getPaginated"
            :get-status="getStatus"
            :loading="getLoading"
            :get2fa-status="get2FaStatus"
            @set-filtered-users="setFilteredUsers"
            @set-filters="setFilters"
            @set-page-no="setPageNo"
            @set-page-size="setPageSize"
            @set-search="setSearch"
            @open-user-modal="openUserModal"
            @open-modal="openModal"
          />
        </div>
      </div>
    </div>
    <!-- USER MODAL -->
    <UserModal
      :modal-state="modalState"
      :clear-filters="clearFilters"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import singleSpa from 'single-spa';

import {
  mapGetters, mapActions, mapState, mapMutations,
} from 'vuex';


import UsersTable from './partials/Table.vue';
import UserModal from './UserModal.vue';


export default {
  name: 'Users',

  components: { UsersTable, UserModal },

  data() {
    const self = this;

    return {
      modalState: {},
      loading: false,
      userAuthData: {},
      actionLoading: false,
      statuses: [{
        status: 'A',
        name: self.$t('statuses.active'),
        type: 'success',
      }, {
        status: 'I',
        name: self.$t('statuses.invited'),
        type: 'warning',
      }, {
        status: 'B',
        name: self.$t('statuses.deactivated'),
        type: 'info',
      }],

      userStatus: 'A',

      searchInput: '',
      isMoreFilterVisible: false,
      enforce2fa: false,
      isImpersonation: Boolean(localStorage.getItem('cpv3Impersonate')),
      countedFilters: ['status'],
      defaultFilters: {
        status: '',
      },
    };
  },

  computed: {
    ...mapState('common', ['auth']),
    ...mapGetters('usermanagement', [
      'getUsers',
      'getFilteredUsers',
      'getPageNo',
      'getPageSize',
      'getSearch',
      'getFilters',
      'getPaginated',
      'getLoading',
    ]),
    ...mapGetters('saml', ['getConfig']),

    filterCount() {
      const filters = this.countedFilters.reduce((a, b) => {
        if (Object.keys(this.defaultFilters).some(f => f === b)) {
          // eslint-disable-next-line no-param-reassign
          a.default[b] = this.defaultFilters[b];
          // eslint-disable-next-line no-param-reassign
          a.touched[b] = this.getFilters[b];
        }

        return a;
      }, { default: {}, touched: {} });

      const affectedFilters = Object.keys(filters.default).reduce((a, b) => {
        if (filters.default[b] !== filters.touched[b]) {
          a.push(b);
        }

        return a;
      }, []);


      return affectedFilters.length;
    },
  },

  watch: {
    getLoading() {},
  },

  created() {
    this.getUserData();
    this.defaultFilters = {
      status: this.userStatus,
    };
    // this is for checking user
    this.loading = true;
    Promise.all([
      this.getAuthUser(),
      this.getSubAccounts({ AccountUid: this.userAuthData.AccountUid }),
    ])
      .then(() => {
        this.userAuthData = this.auth.data;

        // Check the account config
        if (this.userAuthData.AccountConfig) {
          const accountConfig = JSON.parse(this.userAuthData.AccountConfig);
          this.enforce2fa = accountConfig.Enforce2FA;
        }

        if (this.userAuthData.AccessLevel === 'U'
          && !this.userAuthData.Roles.some(v => v.match(/UserManagement/i))) {
          throw new Error('Access Forbidden');
        }

        if (this.userAuthData.AccessLevel === 'A'
          || (this.userAuthData.AccessLevel === 'U' && (this.userAuthData.Roles.includes('UserManagement.ViewUsers_v2') || this.userAuthData.Roles.includes('UserManagement.ManageUsers_v2')))) {
          return this.getAllUsers();
        }

        return Promise.resolve();
      }).then(() => {
        if (this.userAuthData.AccessLevel === 'A'
          || (this.userAuthData.AccessLevel === 'U' && this.userAuthData.Roles.includes('UserManagement.ManageSSOSettings_v2'))) {
          return this.getSamlConfig();
        }
        return Promise.resolve();
      })
      .catch((err) => {
        if (err && err.message === 'Unauthorized') {
          this.$showError(this, err);
          localStorage.removeItem('WWW-Authenticate');
          localStorage.removeItem('cpv3Impersonate');
          localStorage.removeItem('CPV3_User');
          localStorage.removeItem('passwordResetSkipped');
          localStorage.removeItem('user_country');
          sessionStorage.removeItem('sessionHideSuggestEnableTwoFa');
          // singleSpa.navigateToUrl('/login');
          //
        } if (err && err.message === 'Access Forbidden') {
          // this.$message.error('Access Forbidden!');
          this.$showError(this, err);
          singleSpa.navigateToUrl('/403');
        } else {
          this.$showError(this, err);
          // this.$message.error('Error occurred. Please refresh page.');
        }
      })
      .finally(() => {
        this.loading = false;
      });

    // this.getAllUsers()
    //   .catch((err) => {
    //     if (err && err.message === 'Unauthorized') {
    //       localStorage.removeItem('WWW-Authenticate');
    //       // singleSpa.navigateToUrl('/login');
    //       this.$showError(this, err);
    //     } else {
    //       this.$showError(this, err);
    //       // this.$message.error('Error occurred. Please refresh page.');
    //     }
    //   });
  },

  // mounted() {
  //   window.addEventListener('localeChanged', () => {
  //     this.$i18n.locale = this.$getSelectedLocale();
  //   });
  // },

  methods: {
    ...mapActions({
      getAllUsers: 'usermanagement/getUsers',
      getAuthUser: 'common/getAuthUser',
      getSamlConfig: 'saml/get',
      updateSamlConfig: 'saml/update',
      getSubAccounts: 'usermanagement/getSubAccounts',
      updateAccountConfig: 'usermanagement/updateAccountConfig',
    }),

    ...mapMutations({
      setFilters: 'usermanagement/SET_FILTERS',
      setSearch: 'usermanagement/SET_SEARCH',
      setFilteredUsers: 'usermanagement/SET_FILTERED_USERS',
      setPageNo: 'usermanagement/SET_PAGE_NO',
      setPageSize: 'usermanagement/SET_PAGE_SIZE',
      resetFilters: 'usermanagement/RESET_FILTERS',
    }),

    refresh() {
      this.getAllUsers(false);
    },
    clearFilters() {
      this.userStatus = 'A';
      this.searchInput = null;
      this.getAllUsers();
    },

    getUserData() {
      try {
        const user = localStorage.getItem('CPV3_User');

        this.userAuthData = JSON.parse(user) || {};
      } catch (e) {
      // do nothing
      }
    },

    disable() {
      this.$confirm(this.$t('user_management.index[7]'), this.$t('user_management.index[8]'), {
        confirmButtonText: this.$t('user_management.index[9]'),
        cancelButtonText: this.$t('actions.cancel'),
        confirmButtonClass: 'el-button--danger',
        type: 'warning',
      }).then(() => {
        const config = this.getConfig;
        this.updateData({ enabled: false, accountUid: config.AccountUid }, 'disable');
      }).catch(() => {

      });
    },

    enable() {
      const config = this.getConfig;
      this.updateData({ enabled: true, accountUid: config.AccountUid }, 'enable');
    },

    updateData(payload, action = '') {
      this.actionLoading = true;

      this.updateSamlConfig(payload).then(() => this.getSamlConfig()).then(() => {
        if (action) {
          const msg = action === 'enable' && payload.enabled
            ? this.$t('success.enabled', { value: this.$t('user.sso_sign_on') })
            : this.$t('success.disabled', { value: this.$t('user.sso_sign_on') });
          this.$notify.success({
            title: msg,
          });
        } else {
          this.$notify.success({
            title: this.$t('success.updated', { value: this.$t('user.sso_sign_on') }),
          });
        }
      }).catch((err) => {
        this.$showError(this, err);
      })
        .finally(() => {
          this.actionLoading = false;
        });
    },

    openUserModal(user) {
      const auth = this.userAuthData;
      this.openModal({ user, name: 'ModulePermission', auth });
    },

    openModal(modal) {
      this.modalState = {
        ...modal,
        visible: true,
      };
    },

    getStatus(s) {
      return this.statuses.find(v => v.status === s);
    },

    get2FaStatus(s) {
      const status = {};

      if (s === 'A') {
        status.name = 'App';
        status.icon = 'icon-novo-tile';
      }

      if (s === 'S') {
        status.name = 'SMS';
        status.icon = 'icon-novo-sms';
      }

      return status;
    },

    selectStatus(v) {
      this.setFilters({ status: v });
      this.setPageNo(1);
      this.setFilteredUsers();
    },

    searchUser: _.debounce(
      function search() {
        this.setSearch(this.searchInput);
        this.setPageNo(1);
        this.setFilteredUsers();
      }, 300,
    ),

    enable2fa(value) {
      this.$confirm(
        this.$t(value ? 'confirmations.enforce2fa' : 'confirmations.disable2fa'),
        `${this.$t('actions.enforce2fa')}`, {
          confirmButtonText: this.$t('actions.continue'),
          cancelButtonText: this.$t('actions.cancel'),
          type: 'warning',
        },
      ).then(() => {
        let config = {};
        if (this.userAuthData.AccountConfig) {
          const parsedAccountConfig = JSON.parse(this.userAuthData.AccountConfig);
          config = parsedAccountConfig;
        }
        config.Enforce2FA = value;
        this.updateAccountConfig({
          accountUid: this.userAuthData.AccountUid,
          config: {
            Config: JSON.stringify(config),
          },
        }).then(() => {
          this.$message.success('Account config is updated');
        }).catch((err) => {
          // this.$message.error(`Oops, Failed to update account config`);
          this.enforce2fa = !this.enforce2fa;
          this.$showError(this, err);
        }).finally(() => {
          this.loading = false;
        });
      }).catch(() => {
        this.enforce2fa = !this.enforce2fa;
      });
    },
  },
};
</script>
<style scoped>
                                                                                                                                                                                                                                                                                                                                                                                                                    .u-container {
  /* padding: 45px 20px 0 50px; */
  /* margin-top: 45px; */
}

.usr-container {
  padding: 35px 20px 20px 50px;
}
</style>
