//
//
//
//
//
//


export default {
  name: 'App',

  data() {
    return {};
  },

};
