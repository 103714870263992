//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'SSOConfiguration',

  props: {
    closed: {
      type: Boolean,
      default: false,
    },

    user: {
      type: Object,
      default: () => ({}),
    },

    type: {
      type: String,
      default: 'create',
    },
  },

  data() {
    // const validateDomains = (rule, value, callback) => {
    //   // eslint-disable-next-line no-useless-escape
    //   const urlRe = new RegExp(/((http(s)?(\:\/\/))+(www\.)?([\w\-\.\/])*((:[0-9]+)|(\.[a-zA-Z]{2,3}\/?)))[^\s\b\n|]*[^.,;:\?\!\@\^\$ -]/, 'g');
    //   let str = value;

    //   str = str.replace(/\r|\s|\n/g, '_');

    //   str = str.replace(/_+/g, '_');

    //   let domains = str.split('_');

    //   let matched = false;

    //   domains = domains.filter(v => v);

    //   if (domains.length > 1) {
    //     matched = domains.some(v => v.match(urlRe));
    //   }

    //   if (domains.length === 1) {
    //     matched = domains[0].match(urlRe);
    //   }

    //   if (matched) {
    //     callback();
    //   } else {
    //     callback(new Error('Invalid domain has been detected.'));
    //   }
    // };

    const port = window.location.port ? `:${window.location.port}` : '';
    const hostUrl = `${window.location.protocol}//${window.location.hostname}${port}`;
    const self = this;

    return {
      loading: false,
      editable: true,
      appURL: hostUrl,
      form: {
        providerURL: '',
        providerIssuer: '',
        certificate: '',
        enabled: true,
        // domains: '',
      },

      rules: {
        providerURL: [{
          required: true,
          trigger: 'blur',
          message: self.$t('validations.required', { value: 'Identity Provider URL' }),
        }, {
          // eslint-disable-next-line no-useless-escape
          pattern: /((http(s)?(\:\/\/))+(www\.)?([\w\-\.\/])*((:[0-9]+)|(\.[a-zA-Z]{2,3}\/?)))[^\s\b\n|]*[^.,;:\?\!\@\^\$ -]/g,
          message: self.$t('validations.valid', { value: 'Identity Provider URL' }),
          trigger: 'blur',
        }],

        providerIssuer: [{
          required: true,
          trigger: 'blur',
          message: self.$t('validations.required', { value: 'Identity Provider Issuer' }),
        }, {
          // eslint-disable-next-line no-useless-escape
          pattern: /((http(s)?(\:\/\/))+(www\.)?([\w\-\.\/])*((:[0-9]+)|(\.[a-zA-Z]{2,3}\/?)))[^\s\b\n|]*[^.,;:\?\!\@\^\$ -]/g,
          message: self.$t('validations.valid', { value: 'Identity Provider Issuer' }),
          trigger: 'blur',
        }],

        certificate: [{
          required: true,
          trigger: 'blur',
          message: self.$t('validations.required', { value: 'Certificate' }),
        }],

        // domains: [{
        //   required: true,
        //   message: 'Domains should not be empty.',
        // }, {
        //   // eslint-disable-next-line no-useless-escape
        //   validator: validateDomains,
        //   message: 'Invalid domain has been detected.',
        //   trigger: 'blur',
        // }],
      },
    };
  },

  computed: {
    ...mapGetters('saml', ['getConfig']),
  },


  created() {
    if (this.type === 'update') {
      this.editable = false;
      this.fetchData();
    }
  },

  methods: {
    ...mapActions({
      getSaml: 'saml/get',
      updateSaml: 'saml/update',
      removeSaml: 'saml/delete',
      saveSaml: 'saml/save',
    }),

    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const {
            providerURL: ssoUrl,
            providerIssuer: issuer,
            certificate,
            enabled,
          } = this.form;

          const payload = {
            ssoUrl,
            issuer,
            certificate,
            enabled,
          };

          this.saveData(payload);
        }
      });
    },

    edit() {
      this.editable = true;
    },

    disable() {
      this.$confirm(this.$t('user_management.index[7]'), this.$t('user_management.index[8]'), {
        confirmButtonText: this.$t('user_management.index[9]'),
        cancelButtonText: this.$t('actions.cancel'),
        confirmButtonClass: 'el-button--danger',
        type: 'warning',
      }).then(() => {
        const config = this.getConfig;
        this.updateData({ enabled: false, accountUid: config.AccountUid }, 'disable');
      }).catch(() => {
      });
    },

    enable() {
      const config = this.getConfig;
      this.updateData({ enabled: true, accountUid: config.AccountUid }, 'enable');
    },

    update() {
      const config = this.getConfig;

      this.$refs.form.validate((valid) => {
        if (valid) {
          const {
            providerURL: ssoUrl,
            providerIssuer: issuer,
            certificate,
          } = this.form;

          const payload = {
            ssoUrl,
            issuer,
            certificate,
            accountUid: config.AccountUid,
          };

          this.updateData(payload);

          if (this.$refs.form) {
            this.$refs.form.clearValidate();
          }
          this.editable = false;
        }
      });
    },

    back() {
      this.editable = false;

      if (this.$refs.form) {
        this.$refs.form.clearValidate();
      }
      this.fetchData();
    },

    remove() {
      this.$confirm(this.$t('user_management.sso_config[6]'), this.$t('user_management.sso_config[7]'), {
        confirmButtonText: this.$t('user_management.sso_config[8]'),
        cancelButtonText: this.$t('actions.cancel'),
        confirmButtonClass: 'el-button--danger',
        type: 'warning',
      }).then(() => {
        const config = this.getConfig;
        this.removeData({ accountUid: config.AccountUid });
      }).catch(() => {

      });
    },

    saveData(payload) {
      this.loading = true;

      this.saveSaml(payload).then(() => this.getSaml()).then(() => {
        this.$message.success(this.$t('user_management.index[0]'));
        this.reset();
        this.$emit('closeModal');
      }).catch(() => {
        this.$message.error('Unable to save SSO configuration. Please try again.');
      })
        .finally(() => {
          this.loading = false;
        });
    },

    fetchData() {
      this.loading = true;

      this.getSaml().then(() => {
        const config = this.getConfig;

        if (config && Object.keys(config).length) {
          const {
            Certificate: cert, Issuer: issuer, SSO_Url: url, Enabled: enabled,
          } = config;

          this.form.providerURL = url;
          this.form.providerIssuer = issuer;
          this.form.certificate = cert;
          this.form.enabled = enabled;
        }
      }).catch((err) => {
        this.$showError(this, err);
        // this.$message.error('Unable to get SSO configuration. Please try again.');
      }).finally(() => {
        this.loading = false;
      });
    },

    updateData(payload, action = '') {
      this.loading = true;

      this.updateSaml(payload).then(() => this.getSaml()).then(() => {
        if (action) {
          const msg = action === 'enable' && payload.enabled
            ? this.$t('success.enabled', { value: this.$t('user.sso_sign_on') })
            : this.$t('success.disabled', { value: this.$t('user.sso_sign_on') });
          this.$message.success(msg);
          this.reset();
          this.$emit('closeModal');
        } else {
          this.$message.success(this.$t('success.updated', { value: this.$t('user.sso_sign_on') }));
        }
      }).catch((err) => {
        this.$showError(this, err);
        this.$message.error('Unable to update SSO configuration. Please try again.');
      })
        .finally(() => {
          this.loading = false;
        });
    },

    removeData(payload) {
      this.loading = true;

      this.removeSaml(payload).then(() => this.getSaml()).then(() => {
        this.$message.success(this.$message.success(this.$t('success.removed', { value: this.$t('user.sso_sign_on') })));
        this.reset();
        this.$emit('closeModal');
      }).catch((err) => {
        this.$showError(this, err);
        this.$message.error('Unable to remove SSO configuration. Please try again.');
      }).finally(() => {
        this.loading = false;
      });
    },

    cancel() {
      this.reset();
      this.$emit('closeModal');
    },

    reset() {
      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.form = {
        providerURL: '',
        providerIssuer: '',
        certificate: '',
        enabled: true,
        // domains: '',
      };
    },
  },
};
