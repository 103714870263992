<template>
  <div class="overflow-hidden">
    <div
      class="slider"
    >
      <!-- Permission -->
      <div
        v-if="slide === 1"
        class="slider__card"
      >
        <div
          v-if="tempCurrUser.Firstname && tempCurrUser.Lastname"
          slot="title"
          class="text-xl"
        >
          {{ tempCurrUser.Firstname + ' ' + tempCurrUser.Lastname }}
        </div>
        <div
          v-else
          class="text-xl"
        >
          {{ tempCurrUser.Login }}
        </div>
        <div class="flex mt-2">
          <div
            v-if="tempCurrUser.Firstname || tempCurrUser.Lastname"
            class="mr-8"
          >
            <span class="text-grey text-sm">{{ $t('fields.email') }}</span>
            <div class="mt-2 text-sm">
              {{ tempCurrUser.Login }}
            </div>
          </div>
          <div>
            <span class="text-grey text-sm">
              {{ $t('column_labels.date_invited') }} {{ getTz.timeZone }}
            </span>
            <el-tooltip
              v-if="tempCurrUser.InvitedBy_Login && tempCurrUser.CreatedAt"
              class="item"
              effect="dark"
              placement="bottom"
              :content="`${$t('column_labels.date_invited')} ${tempCurrUser.InvitedBy_Login}`"
            >
              <div class="mt-2 text-sm cursor-pointer">
                {{
                  formatDate(tempCurrUser.CreatedAt, {
                    utcOffset: getTz.timeZoneOffset,
                  })
                }}
              </div>
            </el-tooltip>
            <div
              v-else-if="!tempCurrUser.InvitedBy_Login && tempCurrUser.CreatedAt"
              class="mt-2 text-sm"
            >
              {{
                formatDate(tempCurrUser.CreatedAt, {
                  utcOffset: getTz.timeZoneOffset,
                })
              }}
            </div>
            <div
              v-else
              class="mt-2 text-grey-dark"
            >
              {{ $t('statuses.pending') }}
            </div>
          </div>
        </div>
        <form
          class="mt-8 border-t border-grey-light border-solid"
        >
          <div
            v-loading="loading"
            class="border-grey-light border-solid border-b"
          >
            <!-- Messaging Fields -->
            <div class="flex justify-between py-8">
              <div class="w-full">
                <div class="flex justify-between mt-5">
                  <div class="flex-1">
                    <div class="flex flex-col">
                      <span class="text-black block mb-2">{{ $tc('user_management.permission[0]', 1) }}</span>
                      <div>
                        <el-select
                          v-model="tempCurrUser.AccessLevel"
                          :placeholder="$tc('user_management.permission[0]', 2)"
                          class="mr-2 w-48"
                          :disabled="isSelf"
                          size="small"
                        >
                          <el-option
                            v-for="option in permissionOptions"
                            :key="option.value"
                            :label="option.label"
                            :value="option.value"
                            :disabled="option.value === 'A' && accountUser.AccessLevel === 'U'"
                          />
                        </el-select>

                        <a
                          v-if="tempCurrUser.AccessLevel === 'U' && !isSelf"
                          class="text-blue text-xs no-underline
                            hover:text-blue-light inline-block cursor-pointer"
                          @click="changeSlide(2)"
                        >
                          {{ $t('user_management.permission[1]') }}
                          <i class="el-icon-arrow-right" />
                        </a>

                        <div
                          v-if="tempCurrUser.AccessLevel === 'U'"
                          class="mt-4"
                        >
                          <div class="mt-2">
                            <span class="text-black block mb-2"> {{ $t('user_management.permission[2]') }}</span>
                            <div
                              v-if="tempCurrUser.Roles.length"
                              class="block mt-1"
                            >
                              <el-tag
                                class="mb-1 mr-2 text-black"
                                type="info"
                                size="small"
                              >
                                {{ prettifyLabel(tempCurrUser.Roles[0].trim()) }}
                              </el-tag>
                              <el-tooltip
                                v-if="tempCurrUser.Roles.length-1 > 0"
                                placement="right"
                              >
                                <div
                                  slot="content"
                                  class="w-48"
                                >
                                  {{ concatModules(tempCurrUser.Roles.slice(1)) }}
                                </div>
                                <el-tag
                                  class="cursor-pointer mb-1 mr-2 text-black"
                                  type="info"
                                  size="small"
                                >
                                  {{ `+${tempCurrUser.Roles.length-1} more` }}
                                </el-tag>
                              </el-tooltip>
                            </div>
                            <div
                              v-else
                              class="mt-2"
                            >
                              <span class="text-xs text-blue-light"> {{ $t('user_management.permission[3]') }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-show="tempCurrUser.AccessLevel === 'U'"
                    class="flex-1 ml-5 subaccount-option"
                  >
                    <span class="text-black block mb-2">{{ $t('fields.subaccounts') }}</span>
                    <el-select
                      v-model="tempCurrUser.AllowedSubAccounts"
                      placeholder="No Sub-accounts"
                      multiple
                      collapse-tags
                      class="mr-2 w-3/4 block subaccount-field"
                      :disabled="!subAccounts.length || isSelf"
                      size="small"
                    >
                      <el-option-group :label="$t('user_management.permission[6]')">
                        <el-option
                          v-for="option in subAccounts"
                          :key="option.SubAccountId"
                          :label="option.SubAccountId"
                          :value="option.SubAccountId"
                        >
                          <span class="w-full truncate pr-5">{{ option.SubAccountId }}</span>
                        </el-option>
                      </el-option-group>
                    </el-select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="pt-10">
            <p
              v-if="!tempCurrUser.Roles.length"
              class="text-right my-4 text-sm text-red-light"
            >
              {{ $t('user_management.permission[4]') }}
            </p>

            <!-- Buttons -->
            <div
              v-if="!isSelf && tempCurrUser"
              class="flex justify-between"
            >
              <el-button
                v-if="tempCurrUser.UserStatus === 'B'"
                type="warning"
                plain
                :disabled="loading"
                size="small"
                @click="activate"
              >
                {{ $t('actions.reactivate') }}
              </el-button>
              <el-button
                v-else-if="tempCurrUser.UserStatus === 'A'"
                type="warning"
                plain
                :disabled="loading"
                size="small"
                @click="deactivate"
              >
                {{ $t('actions.deactivate') }}
              </el-button>
              <el-button
                v-else
                type="warning"
                plain
                :disabled="loading"
                size="small"
                @click="reinvite"
              >
                {{ $t('actions.resend_invite') }}
              </el-button>

              <el-button
                type="danger"
                size="small"
                plain
                :disabled="loading"
                @click="removeUser"
              >
                {{ $t('actions.remove_user') }}
              </el-button>

              <el-button
                v-if="tempCurrUser.UserStatus !== 'B'"
                type="primary"
                :disabled="loading"
                size="small"
                @click="saveChanges"
              >
                {{ $t('actions.save_changes') }}
              </el-button>
            </div>
          </div>
        </form>
      </div>

      <div
        v-if="slide === 2"
        class="slider__card"
      >
        <!-- Modules -->
        <ModuleList
          :name="tempCurrUser.Login"
          :all-roles="allRoles"
          :user-roles="tempCurrUser.Roles"
          :account-user="accountUser"
          :prettify-label="prettifyLabel"
          :apply="applyCheckedRoles"
          :is-invite="Boolean(false)"
          @backEvent="changeSlide(1)"
          @closeModal="closeParentModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
// import singleSpa from 'single-spa';
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash';

import utils from '../common/helper';

// eslint-disable-next-line import/extensions
import timeFormatter from '../mixins/timeFormatter.js';

import ModuleList from './ModuleList.vue';

export default {
  name: 'ModulePermission',

  components: {
    ModuleList,
  },

  mixins: [timeFormatter],

  props: {
    user: {
      type: Object,
      default: () => ({}),
    },

    auth: {
      type: Object,
      default: () => ({}),
    },

    closed: {
      type: Boolean,
      default: true,
    },

    clearFilters: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      loading: false,
      tempCurrUser: this.user,
      accountUser: this.auth,
      slide: 1,

      permissionValue: '',
      isSelf: true,
      allRoles: [],
      selectedUserConfig: {},
      permissionOptions: [
        {
          value: 'A',
          label: 'Admin',
        },
        {
          value: 'U',
          label: 'User',
        },
      ],
    };
  },

  computed: {
    ...mapGetters('usermanagement', ['getReportConfig', 'getAllModules']),
    ...mapGetters('common', ['getTz']),
    ...mapState(
      'usermanagement', ['subAccounts', 'currUser', 'modules'],
    ),

    // showVideoField() {
    //   let ret = false;
    //   if (this.tempCurrUser.NewRoles) {
    //     if (this.tempCurrUser.NewRoles.Video.tenant) {
    //       ret = true;
    //     }
    //   }
    //   return ret;
    // },
  },

  watch: {
    closed() {
      this.changeSlide(1);
    },
  },

  created() {
    this.loading = true;
    this.accountUser = this.auth;


    // check if user is allowed to invite user
    this.getAuthUser()
      .then((response) => {
        if (!response.data.Roles.some(v => v.match(/UserManagement/i))
          && response.data.AccessLevel === 'U') {
          throw new Error('Unauthorized');
        }
      })
      .catch((err) => {
        if (err && err.message === 'Unauthorized') {
          localStorage.removeItem('WWW-Authenticate');
          localStorage.removeItem('cpv3Impersonate');
          localStorage.removeItem('CPV3_User');
          localStorage.removeItem('passwordResetSkipped');
          localStorage.removeItem('user_country');
          sessionStorage.removeItem('sessionHideSuggestEnableTwoFa');
          this.$showError(this, err);
          // singleSpa.navigateToUrl('/login');
        } else {
          this.$showError(this, err);
          // this.showMessage('Error occurred. Please refresh page.', 'error');
        }
      });

    // admin has all the roles, so make this as master list of roles
    // this.allRoles = this.accountUser.Roles;

    // if (account is level has automation feature) {
    //   this.allRoles = this.allRoles.filter((v) => v !== 'Automation_v2')
    // }
    Promise.all([
      this.getCurrentUser(this.user),
      this.getAllRoles()])
      .then((res) => {
        this.tempCurrUser = _.cloneDeep(res[0]);

        this.allRoles = this.getAllModules;
        // eslint-disable-next-line max-len
        this.tempCurrUser.AllowedSubAccounts = this.tempCurrUser.AllowedSubAccounts.map(subAccount => subAccount.SubAccountId);
        this.isSelf = this.accountUser.UserId === this.tempCurrUser.UserId;

        return this.getSmsReportExportConfig({ uId: this.tempCurrUser.UserId });
      })
      .then((res2) => {
        this.selectedUserConfig = res2;
      })
      .catch((err) => {
        if (err && err.message === 'Unauthorized') {
          localStorage.removeItem('WWW-Authenticate');
          // this.$router.push({ path: '/' });
          // singleSpa.navigateToUrl('/login');
          this.$showError(this, err);
        } else {
          this.$showError(this, err);
          // this.showMessage('Error occurred. Please refresh page.', 'error');
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },

  methods: {
    ...mapActions({
      getAllRoles: 'usermanagement/getAllModules',
      getAuthUser: 'common/getAuthUser',
      getCurrentUser: 'usermanagement/getCurrentUser',
      updateUser: 'usermanagement/updateUser',
      getUsers: 'usermanagement/getUsers',
      deleteUser: 'usermanagement/deleteUser',
      resendInvitation: 'usermanagement/resendInvitation',
      updateSmsReportExportConfig: 'usermanagement/updateSmsReportExportConfig',
      getSmsReportExportConfig: 'usermanagement/getSmsReportExportConfig',
      removeSmsReportExportConfigByUserId: 'usermanagement/removeSmsReportExportConfigByUserId',
    }),

    applyCheckedRoles(roles) {
      this.tempCurrUser.Roles = roles;
    },

    prettifyLabel(string) {
      return utils.prettifyLabel(string);
    },

    concatModules(modules) {
      return utils.concatModules(modules);
    },

    // Resend Invite
    reinvite() {
      const { UserId, Login } = this.user;
      this.loading = true;
      this.resendInvitation({
        userId: UserId,
        data: {
          email: Login,
          invitedByUserId: this.user.InvitedBy_UserId,
        },
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: this.$t('user_management.permission[7]', { email: Login }),
          });
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: `Oops, Failed to sent invite to ${Login}.`,
          });
        }).finally(() => {
          this.loading = false;
        });
    },

    showMessage(message, type) {
      this.$message({
        showClose: true,
        message,
        type,
      });
    },

    // Save Changes
    saveChanges() {
      const {
        UserId: userId,
        Roles,
        AllowedSubAccounts: subAccounts,
        AccessLevel,
      } = this.tempCurrUser;

      if (!Roles.length) {
        this.$message.error(this.$t('user_management.permission[8]'));
        return;
      }

      this.$confirm(
        this.$t('confirmations.save_changes'),
        `${this.$t('actions.update')} ${this.$t('column_labels.user')}`, {
          confirmButtonText: this.$t('actions.save'),
          cancelButtonText: this.$t('actions.cancel'),
          type: 'warning',
        },
      ).then(() => {
        // Save
        const formatSubAccounts = (AccessLevel === 'A' ? this.subAccounts : subAccounts).map(subAcc => ({
          SubAccountId: AccessLevel === 'A' ? subAcc.SubAccountId : subAcc,
        }));

        const limitRoles = (AccessLevel !== 'A'); // 0 is Admin, 1 is User
        const limitSubAccounts = (AccessLevel !== 'A');

        this.loading = true;

        // Update roles, subaccounts, and accesslevel
        this.updateUser({
          userId,
          roles: AccessLevel === 'A' ? this.modules : Roles,
          accessLevel: AccessLevel,
          subAccounts: formatSubAccounts,
          updateType: ['ROLES', 'SUBACCOUNTS', 'ACCESSLEVEL'],
        })
          .then(() => {
            this.$message({
              type: 'success',
              message: this.$t('success.updated', { value: this.user.Login }),
            });

            this.$emit('closeModal');

            this.getUsers(true);
          })
          .then(() => {})
          .catch((err) => {
            this.$showError(this, err);
          })
          .finally(() => {
            this.loading = false;
          });
      }).catch(() => {});
    },

    // Deactivate User
    deactivate() {
      this.$confirm(
        this.$t('confirmations.deactivate', { item: this.user.Login }),
        `${this.$t('actions.deactivate')} ${this.$t('column_labels.user')}`, {
          confirmButtonText: this.$t('actions.deactivate'),
          cancelButtonText: this.$t('actions.cancel'),
          type: 'warning',
        },
      ).then(() => {
        this.loading = true;
        this.updateUser({
          userId: this.user.UserId,
          updateType: ['BLOCK'],
          userStatus: 'B',
        }).then(() => {
          this.updateSmsReportExportConfig({ uID: this.user.UserId, emptyRunAt: 1 });

          this.$message({
            type: 'success',
            message: this.$t('success.deactivated', { value: this.user.Login }),
          });

          this.$emit('closeModal');

          this.getUsers(true);
        }).catch(() => {
          this.$message.error(`Oops, Failed to deactivate ${this.user.Login}`);
        })
          .finally(() => {
            this.loading = false;
          });
      }).catch(() => {});
    },

    // Activate User
    activate() {
      const {
        UserId: userId,
        Roles,
        AllowedSubAccounts: subAccounts,
        AccessLevel,
      } = this.tempCurrUser;

      if (!Roles.length) {
        this.$message.error(this.$t('user_management.permission[8]'));
        return;
      }

      this.$confirm(
        this.$t('confirmations.reactivate', { item: this.user.Login }),
        `${this.$t('actions.reactivate')} ${this.$t('column_labels.user')}`, {
          confirmButtonText: this.$t('actions.confirm'),
          cancelButtonText: this.$t('actions.cancel'),
          type: 'warning',
        },
      ).then(() => {
        // Re-activate
        const formatSubAccounts = subAccounts.map(subAcc => ({
          SubAccountId: subAcc,
        }));

        const limitRoles = (AccessLevel !== 'A'); // 0 is Admin, 1 is User
        const limitSubAccounts = (AccessLevel !== 'A');

        // Update user status
        this.loading = true;

        this.updateUser({
          userId,
          userStatus: 'A',
          roles: Roles,
          accessLevel: AccessLevel,
          subAccounts: formatSubAccounts,
          updateType: ['UNBLOCK', 'ACCESSLEVEL', 'ROLES', 'SUBACCOUNTS'],
        })
          .then(() => {
            // add guard when reports config API returns empty
            if (this.selectedUserConfig) {
              const { Frequency: frequency, PreferredDay: preferredDay } = this.selectedUserConfig;
              if (frequency && preferredDay) {
                this.updateSmsReportExportConfig({
                  frequency,
                  preferredDay,
                  uID: this.user.UserId,
                });
              }
            }

            this.$message({
              type: 'success',
              message: this.$t('success.activated', { value: this.user.Login }),
            });

            this.$emit('closeModal');

            this.getUsers(true);
          })
          .catch(() => {
            this.$message.error(`Oops, Failed to activate ${this.user.Login}`);
          })
          .finally(() => {
            this.loading = false;
          });
      }).catch(() => {});
    },

    // Remove User
    removeUser() {
      const { Login, UserId } = this.user;
      this.$confirm(
        this.$t('confirmations.remove', { item: this.user.Login }),
        `${this.$t('actions.remove')} ${this.$t('column_labels.user')}`, {
          confirmButtonText: this.$t('actions.remove'),
          cancelButtonText: this.$t('actions.cancel'),
          type: 'warning',
        },
      ).then(() => {
        this.loading = true;

        this.deleteUser(UserId)
          .then(() => {
            this.clearFilters();
            this.$emit('closeModal');
            this.$message({
              message: this.$t('success.removed', { value: Login }),
              type: 'success',
            });

            this.removeSmsReportExportConfigByUserId({ uID: UserId });

            this.getUsers(true);
          })
          .catch(() => {
            this.$message.error(`Oops, Failed to remove ${Login}`);
          }).finally(() => {
            this.loading = false;
          });
      }).catch(() => {});
    },

    changeSlide(n) {
      this.slide = Number(n);
    },

    closeParentModal() {
      this.$emit('closeModal');
    },
  },
};
</script>

<style lang="scss">
.el-dialog__body {
  padding-top: 0;
}

.slider {
  position: relative;
  white-space: nowrap;
  transition: transform 0.3s ease
}

.subaccount-option {
  .el-tag {
    max-width: 135px !important;
    overflow: hidden !important;
  }
}
</style>
