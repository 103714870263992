<template>
  <section class="h-full">
    <RouterView />
  </section>
</template>

<script>

export default {
  name: 'App',

  data() {
    return {};
  },

};
</script>

<style>
/* .wh */
</style>
