<template>
  <el-dialog
    id="usermanagement-modal"
    :visible.sync="modalState.visible"
    :close-on-click-modal="false"
    :show-close="showClose"
    top="50px"
    :class="`u-modal modal-${modalState.name ? modalState.name.toLowerCase() : ''}`"
    @open="toggleModal(true)"
    @closed="toggleModal(false)"
  >
    <!-- Module Permission Modal -->
    <ModulePermission
      v-if="modalState.name === 'ModulePermission'"
      :user="modalState.user"
      :auth="modalState.auth"
      :closed="isVisible"
      :clear-filters="clearFilters"
      @closeModal="toggleModal(false)"
    />

    <!-- Invite User Modal -->
    <InviteUser
      v-else-if="modalState.name === 'InviteUser'"
      class="invite-user"
      :closed="isVisible"
      :auth="modalState.user"
      @toggleShowClose="toggleShowClose"
      @closeModal="toggleModal(false)"
    />

    <!-- SSO Config User Modal -->
    <SSOConfiguration
      v-else-if="modalState.name === 'SSOConfiguration'"
      class="sso-config"
      :user="modalState.user"
      :type="modalState.type"
      :closed="isVisible"
      @toggleShowClose="toggleShowClose"
      @closeModal="toggleModal(false)"
    />
  </el-dialog>
</template>

<script>
import ModulePermission from './partials/ModulePermission.vue';
import InviteUser from './partials/InviteUser.vue';
import SSOConfiguration from './partials/SSOConfiguration.vue';

export default {
  name: 'UserModal',

  components: {
    ModulePermission,
    InviteUser,
    SSOConfiguration,
  },

  props: {
    modalState: {
      type: Object,
      default: () => ({
        user: {},
        name: 'ModulePermission',
        visible: false,
      }),
    },

    clearFilters: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      isVisible: false,
      showClose: true,
    };
  },

  methods: {
    toggleModal(bool) {
      this.modalState.visible = bool;
      this.isVisible = bool;
      this.modalState.name = bool ? this.modalState.name : '';
    },

    toggleShowClose(bool) {
      this.showClose = bool;
    },
  },
};
</script>

<style lang="scss">
.u-modal {
  .el-dialog {
    width: 700px;
  }
}
</style>
