<template>
  <div
    v-loading="loading"
    class="sso-form"
  >
    <h1
      v-if="type === 'update' && editable"
      class="text-lg"
    >
      {{ $t('user_management.sso_config[0]') }}
    </h1>
    <h1
      v-else
      class="text-lg"
    >
      {{ $t('user_management.index[3]') }}
    </h1>
    <p
      v-if="type === 'create' && editable"
      class="my-5 text-xs"
    >
      {{ $t('user_management.sso_config[1]') }}
    </p>

    <div
      v-if="type === 'update' && !form.enabled"
      class="my-5 text-sm p-2 border border-blue-light rounded flex text-xs"
    >
      <div class="flex-shrink">
        <i
          class="el-icon-info text-gray-darker align-middle mr-1"
          :style="{ fontSize: '18px'}"
        />
      </div>
      <div class="flex-auto text-center">
        {{ $t('user_management.index[1]') }}
      </div>
    </div>

    <div
      v-if="type === 'update' && form.enabled"
      class="my-5 text-sm p-2 border border-green-light rounded flex text-xs"
    >
      <div class="flex-shrink">
        <i
          class="el-icon-success text-green align-middle mr-1"
          :style="{ fontSize: '18px'}"
        />
      </div>
      <div class="flex-auto text-center">
        {{ $t('user_management.sso_config[2]') }}
      </div>
    </div>
    <div class="mt-10 mb-5">
      <p class="text-gray-dark text-xs font-medium">
        {{ this.$t('user_management.index[0]') }}
      </p>
      <div class="border-l-2 border-blue rounded bg-blue-lightest p-5 mt-3 text-sm break-words">
        {{ `${appURL}/saml/auth/${user.AccountUid}` }}
      </div>
    </div>
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-position="top"
      class="mt-10"
      @submit.prevent.native
    >
      <el-form-item prop="providerURL">
        <slot name="label">
          <h4 class="text-sm">
            Identity Provider URL
          </h4>
          <p
            v-if="type === 'create'"
            class="text-xs text-grey-dark"
          >
            {{ $t('user_management.sso_config[3]') }}
          </p>
          <el-input
            v-model="form.providerURL"
            :disabled="loading || !editable"
            size="small"
            placeholder="For ex. https://${yourDomain}/app/8x8dev296106_customerportal_1/exke0kqyvlw05uf2E4x6/sso/saml"
          />
        </slot>
      </el-form-item>
      <el-form-item prop="providerIssuer">
        <slot name="label">
          <h4 class="text-sm">
            Identity Provider Issuer
          </h4>
          <p
            v-if="type === 'create'"
            class="text-xs text-grey-dark"
          >
            {{ $t('user_management.sso_config[4]') }}
          </p>
          <el-input
            v-model="form.providerIssuer"
            :disabled="loading || !editable "
            size="small"
            placeholder="For ex. http://${yourDomain}/exke0kqyvlw05uf2E4x6"
          />
        </slot>
      </el-form-item>
      <el-form-item prop="certificate">
        <slot name="label">
          <h4 class="text-sm">
            Key x509 Certificate
          </h4>
          <p
            v-if="type === 'create'"
            class="text-xs text-grey-dark"
          >
            {{ $t('user_management.sso_config[5]') }}
          </p>
          <el-input
            v-model="form.certificate"
            :disabled="loading || !editable"
            size="small"
            type="textarea"
            :rows="4"
            placeholder="For ex.
              -----BEGIN CERTIFICATE-----
              MIIBuTCCASKgAwIBAgIQNdNhtuV5GbNHYZsf+LvM0zANBgkqhkiG9w0BAQUFADAb
              MRkwFwYDVQQDExBFZGlkZXYgU21va2VUZXN0MB4XDTA4MTExMjE5NTEzNVoXDTM5
              MTIzMTIzNTk1OVowGzEZMBcGA1UEAxMQRWRpZGV2IFNtb2tlVGVzdDCBnzANBgkq
              hkiG9w0BAQEFAAOBjQAwgYkCgYEAm6zGzqxejwswWTNLcSsa7P8xqODspX9VQBuq
              5W1RoTgQ0LNR64+7ywLjH8+wrb/lB6QV7s2SFUiWDeduVesvMJkWtZ5zzQyl3iUa
              CBpT4S5AaO3/wkYQSKdI108pXH7Aue0e/ZOwgEEX1N6OaPQn7AmAB4uq1h+ffw+r
              RKNHqnsCAwEAATANBgkqhkiG9w0BAQUFAAOBgQCZmj+pgRsN6HpoICawK3XXNAmi
              cgfQkailX9akIjD3xSCwEQx4nG6tZjTz30u4NoSffW7pch58SxuZQDqW5NsJcQNq
              Ngo/dMoqqpXdi2/0BYEcJ8pjsngrFm+fM2BnyGpXH7aWuKsWjVFGlWlF+yi8I35Q
              8wFJt2Z/XGA7WWDjvw==
              -----END CERTIFICATE-----"
          />
        </slot>
      </el-form-item>
      <!-- <el-form-item prop="domains">
        <slot name="label">
          <h4 class="text-sm">
            Domains
          </h4>
          <p class="text-xs text-grey-dark">
            Specifiy the domains which are allowed to authenticate with SAML SSO
          </p>
        </slot>
        <el-input
          v-model="form.domains"
          size="small"
          type="textarea"
          :rows="4"
          placeholder="For ex. https://yourdomain.com/"
        />
      </el-form-item> -->

      <div
        v-if="editable && type === 'create'"
        class="mt-10 mb-5"
      >
        <el-button
          class="mr-3 w-32"
          type="primary"
          size="small"
          @click="submit"
        >
          {{ $t('actions.save') }}
        </el-button>

        <el-button
          class="w-32"
          size="small"
          @click="cancel"
        >
          {{ $t('actions.cancel') }}
        </el-button>
      </div>

      <div
        v-if="editable && type === 'update'"
        class="mt-10 mb-5"
      >
        <el-button
          class="w-32 mr-3"
          size="small"
          @click="back"
        >
          {{ $t('actions.back') }}
        </el-button>
        <el-button
          class="w-32"
          type="primary"
          size="small"
          @click="update"
        >
          {{ $t('actions.save') }}
        </el-button>
      </div>
      <div
        v-if="!editable && type === 'update'"
        class="mt-10 mb-5 flex"
      >
        <div class="w-1/2">
          <el-button
            class="w-32"
            size="small"
            @click="edit"
          >
            {{ $t('actions.edit') }}
          </el-button>
        </div>
        <div class="w-1/2 text-right">
          <el-button
            v-if="form.enabled"
            class="mr-3 w-32"
            size="small"
            @click="disable"
          >
            {{ $t('actions.disable') }} SSO
          </el-button>

          <el-button
            v-else
            class="mr-3 w-32"
            size="small"
            @click="enable"
          >
            {{ $t('actions.enable') }} SSO
          </el-button>

          <el-button
            class="w-32"
            type="danger"
            size="small"
            @click="remove"
          >
            {{ $t('actions.remove') }}
          </el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'SSOConfiguration',

  props: {
    closed: {
      type: Boolean,
      default: false,
    },

    user: {
      type: Object,
      default: () => ({}),
    },

    type: {
      type: String,
      default: 'create',
    },
  },

  data() {
    // const validateDomains = (rule, value, callback) => {
    //   // eslint-disable-next-line no-useless-escape
    //   const urlRe = new RegExp(/((http(s)?(\:\/\/))+(www\.)?([\w\-\.\/])*((:[0-9]+)|(\.[a-zA-Z]{2,3}\/?)))[^\s\b\n|]*[^.,;:\?\!\@\^\$ -]/, 'g');
    //   let str = value;

    //   str = str.replace(/\r|\s|\n/g, '_');

    //   str = str.replace(/_+/g, '_');

    //   let domains = str.split('_');

    //   let matched = false;

    //   domains = domains.filter(v => v);

    //   if (domains.length > 1) {
    //     matched = domains.some(v => v.match(urlRe));
    //   }

    //   if (domains.length === 1) {
    //     matched = domains[0].match(urlRe);
    //   }

    //   if (matched) {
    //     callback();
    //   } else {
    //     callback(new Error('Invalid domain has been detected.'));
    //   }
    // };

    const port = window.location.port ? `:${window.location.port}` : '';
    const hostUrl = `${window.location.protocol}//${window.location.hostname}${port}`;
    const self = this;

    return {
      loading: false,
      editable: true,
      appURL: hostUrl,
      form: {
        providerURL: '',
        providerIssuer: '',
        certificate: '',
        enabled: true,
        // domains: '',
      },

      rules: {
        providerURL: [{
          required: true,
          trigger: 'blur',
          message: self.$t('validations.required', { value: 'Identity Provider URL' }),
        }, {
          // eslint-disable-next-line no-useless-escape
          pattern: /((http(s)?(\:\/\/))+(www\.)?([\w\-\.\/])*((:[0-9]+)|(\.[a-zA-Z]{2,3}\/?)))[^\s\b\n|]*[^.,;:\?\!\@\^\$ -]/g,
          message: self.$t('validations.valid', { value: 'Identity Provider URL' }),
          trigger: 'blur',
        }],

        providerIssuer: [{
          required: true,
          trigger: 'blur',
          message: self.$t('validations.required', { value: 'Identity Provider Issuer' }),
        }, {
          // eslint-disable-next-line no-useless-escape
          pattern: /((http(s)?(\:\/\/))+(www\.)?([\w\-\.\/])*((:[0-9]+)|(\.[a-zA-Z]{2,3}\/?)))[^\s\b\n|]*[^.,;:\?\!\@\^\$ -]/g,
          message: self.$t('validations.valid', { value: 'Identity Provider Issuer' }),
          trigger: 'blur',
        }],

        certificate: [{
          required: true,
          trigger: 'blur',
          message: self.$t('validations.required', { value: 'Certificate' }),
        }],

        // domains: [{
        //   required: true,
        //   message: 'Domains should not be empty.',
        // }, {
        //   // eslint-disable-next-line no-useless-escape
        //   validator: validateDomains,
        //   message: 'Invalid domain has been detected.',
        //   trigger: 'blur',
        // }],
      },
    };
  },

  computed: {
    ...mapGetters('saml', ['getConfig']),
  },


  created() {
    if (this.type === 'update') {
      this.editable = false;
      this.fetchData();
    }
  },

  methods: {
    ...mapActions({
      getSaml: 'saml/get',
      updateSaml: 'saml/update',
      removeSaml: 'saml/delete',
      saveSaml: 'saml/save',
    }),

    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const {
            providerURL: ssoUrl,
            providerIssuer: issuer,
            certificate,
            enabled,
          } = this.form;

          const payload = {
            ssoUrl,
            issuer,
            certificate,
            enabled,
          };

          this.saveData(payload);
        }
      });
    },

    edit() {
      this.editable = true;
    },

    disable() {
      this.$confirm(this.$t('user_management.index[7]'), this.$t('user_management.index[8]'), {
        confirmButtonText: this.$t('user_management.index[9]'),
        cancelButtonText: this.$t('actions.cancel'),
        confirmButtonClass: 'el-button--danger',
        type: 'warning',
      }).then(() => {
        const config = this.getConfig;
        this.updateData({ enabled: false, accountUid: config.AccountUid }, 'disable');
      }).catch(() => {
      });
    },

    enable() {
      const config = this.getConfig;
      this.updateData({ enabled: true, accountUid: config.AccountUid }, 'enable');
    },

    update() {
      const config = this.getConfig;

      this.$refs.form.validate((valid) => {
        if (valid) {
          const {
            providerURL: ssoUrl,
            providerIssuer: issuer,
            certificate,
          } = this.form;

          const payload = {
            ssoUrl,
            issuer,
            certificate,
            accountUid: config.AccountUid,
          };

          this.updateData(payload);

          if (this.$refs.form) {
            this.$refs.form.clearValidate();
          }
          this.editable = false;
        }
      });
    },

    back() {
      this.editable = false;

      if (this.$refs.form) {
        this.$refs.form.clearValidate();
      }
      this.fetchData();
    },

    remove() {
      this.$confirm(this.$t('user_management.sso_config[6]'), this.$t('user_management.sso_config[7]'), {
        confirmButtonText: this.$t('user_management.sso_config[8]'),
        cancelButtonText: this.$t('actions.cancel'),
        confirmButtonClass: 'el-button--danger',
        type: 'warning',
      }).then(() => {
        const config = this.getConfig;
        this.removeData({ accountUid: config.AccountUid });
      }).catch(() => {

      });
    },

    saveData(payload) {
      this.loading = true;

      this.saveSaml(payload).then(() => this.getSaml()).then(() => {
        this.$message.success(this.$t('user_management.index[0]'));
        this.reset();
        this.$emit('closeModal');
      }).catch(() => {
        this.$message.error('Unable to save SSO configuration. Please try again.');
      })
        .finally(() => {
          this.loading = false;
        });
    },

    fetchData() {
      this.loading = true;

      this.getSaml().then(() => {
        const config = this.getConfig;

        if (config && Object.keys(config).length) {
          const {
            Certificate: cert, Issuer: issuer, SSO_Url: url, Enabled: enabled,
          } = config;

          this.form.providerURL = url;
          this.form.providerIssuer = issuer;
          this.form.certificate = cert;
          this.form.enabled = enabled;
        }
      }).catch((err) => {
        this.$showError(this, err);
        // this.$message.error('Unable to get SSO configuration. Please try again.');
      }).finally(() => {
        this.loading = false;
      });
    },

    updateData(payload, action = '') {
      this.loading = true;

      this.updateSaml(payload).then(() => this.getSaml()).then(() => {
        if (action) {
          const msg = action === 'enable' && payload.enabled
            ? this.$t('success.enabled', { value: this.$t('user.sso_sign_on') })
            : this.$t('success.disabled', { value: this.$t('user.sso_sign_on') });
          this.$message.success(msg);
          this.reset();
          this.$emit('closeModal');
        } else {
          this.$message.success(this.$t('success.updated', { value: this.$t('user.sso_sign_on') }));
        }
      }).catch((err) => {
        this.$showError(this, err);
        this.$message.error('Unable to update SSO configuration. Please try again.');
      })
        .finally(() => {
          this.loading = false;
        });
    },

    removeData(payload) {
      this.loading = true;

      this.removeSaml(payload).then(() => this.getSaml()).then(() => {
        this.$message.success(this.$message.success(this.$t('success.removed', { value: this.$t('user.sso_sign_on') })));
        this.reset();
        this.$emit('closeModal');
      }).catch((err) => {
        this.$showError(this, err);
        this.$message.error('Unable to remove SSO configuration. Please try again.');
      }).finally(() => {
        this.loading = false;
      });
    },

    cancel() {
      this.reset();
      this.$emit('closeModal');
    },

    reset() {
      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.form = {
        providerURL: '',
        providerIssuer: '',
        certificate: '',
        enabled: true,
        // domains: '',
      };
    },
  },
};
</script>
<style lang="scss">
  .el-dialog__body {
    padding-top: 0;
  }

  .sso-form {
    .el-form-item__content {
      line-height: 30px;
    }
  }
</style>
