import { Promise } from 'es6-promise';
import http from '../../../../utils/http';

export default {
  async save({ commit }, payload) {
    try {
      await http.v2.post('saml/config', payload);
      return {
        ...payload,
      };
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async update({ commit }, payload) {
    try {
      const p = { ...payload };

      const id = p.accountUid;
      // eslint-disable-next-line no-param-reassign
      delete payload.accountUid;

      const { data } = await http.v2.patch(`saml/config/${id}`, {
        ...payload,
      });
      commit('SET_CONFIG', {
        ...payload,
        ...data,
      });

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async delete({ commit }, payload) {
    try {
      const id = payload.accountUid;
      const { data } = await http.v2.delete(`saml/config/${id}`);
      commit('SET_CONFIG', {});
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async get({ commit }, payload) {
    try {
      const { data } = await http.v2.get('saml/config');
      if (data && Object.keys(data).length) {
        commit('SET_CONFIG', {
          ...payload,
          ...data,
        });
        return {
          ...data,
        };
      }
      commit('SET_CONFIG', {});
      return {};
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },
};
