import Vue from 'vue';
import Vuex from 'vuex';
// import bugsnag from 'bugsnag-js';

import usermanagement from './usermanagement';
import saml from './saml';
import common from './common';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

// const bugsnagKey = process.env.BUGSNAG_KEY || '6f6b74049a5199fae308b0dec4e59d4e';
// const bugsnagClient = bugsnag(bugsnagKey);

const store = new Vuex.Store({
  state: {
    errors: [],
  },

  mutations: {
    API_ERROR(state, payload) {
      // if (window.Bugsnag) {
      //   window.Bugsnag.notify(payload);
      // }
      // const error = payload
      //   && payload.body
      //   && payload.body.message ? payload.body.message : payload;
      // bugsnagClient.notify(error);
      state.errors.push(payload); // eslint-disable-line no-param-reassign
    },
  },

  actions: {},

  modules: {
    common,
    usermanagement,
    saml,
  },

  strict: debug,
});

export default store;
