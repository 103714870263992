const constantData = {
  SMS_TO_SURVEY_DOWNLOAD_MAX_LIMIT: 50000,
  LOCALE: 'en-US',
  DECIMAL_PLACES: 2,
  SCHEDULED_TIME_LIMIT_IN_MINS: 3,
  PRICE_DECIMAL: 6,
  EMAIL_REGEX: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  URL_REGEX: /((http(s)?(\:\/\/))+(www\.)?([\w\-\.\/])*(\.[a-zA-Z]{2,3}\/?))[^\s\b\n|]*[^.,;:\?\!\@\^\$ -]/, //eslint-disable-line
};

const Constants = {
  install(Vue) {
    Vue.mixin({
      created() {
        // Global constantData instance
        this.$constants = constantData;
      },
    });

    Vue.prototype.constants = constantData; // eslint-disable-line
  },
};

export default Constants;
