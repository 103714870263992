//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapState } from 'vuex';
// import singleSpa from 'single-spa';
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash';

import utils from '../common/helper';

// eslint-disable-next-line import/extensions
import timeFormatter from '../mixins/timeFormatter.js';

import ModuleList from './ModuleList.vue';

export default {
  name: 'ModulePermission',

  components: {
    ModuleList,
  },

  mixins: [timeFormatter],

  props: {
    user: {
      type: Object,
      default: () => ({}),
    },

    auth: {
      type: Object,
      default: () => ({}),
    },

    closed: {
      type: Boolean,
      default: true,
    },

    clearFilters: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      loading: false,
      tempCurrUser: this.user,
      accountUser: this.auth,
      slide: 1,

      permissionValue: '',
      isSelf: true,
      allRoles: [],
      selectedUserConfig: {},
      permissionOptions: [
        {
          value: 'A',
          label: 'Admin',
        },
        {
          value: 'U',
          label: 'User',
        },
      ],
    };
  },

  computed: {
    ...mapGetters('usermanagement', ['getReportConfig', 'getAllModules']),
    ...mapGetters('common', ['getTz']),
    ...mapState(
      'usermanagement', ['subAccounts', 'currUser', 'modules'],
    ),

    // showVideoField() {
    //   let ret = false;
    //   if (this.tempCurrUser.NewRoles) {
    //     if (this.tempCurrUser.NewRoles.Video.tenant) {
    //       ret = true;
    //     }
    //   }
    //   return ret;
    // },
  },

  watch: {
    closed() {
      this.changeSlide(1);
    },
  },

  created() {
    this.loading = true;
    this.accountUser = this.auth;


    // check if user is allowed to invite user
    this.getAuthUser()
      .then((response) => {
        if (!response.data.Roles.some(v => v.match(/UserManagement/i))
          && response.data.AccessLevel === 'U') {
          throw new Error('Unauthorized');
        }
      })
      .catch((err) => {
        if (err && err.message === 'Unauthorized') {
          localStorage.removeItem('WWW-Authenticate');
          localStorage.removeItem('cpv3Impersonate');
          localStorage.removeItem('CPV3_User');
          localStorage.removeItem('passwordResetSkipped');
          localStorage.removeItem('user_country');
          sessionStorage.removeItem('sessionHideSuggestEnableTwoFa');
          this.$showError(this, err);
          // singleSpa.navigateToUrl('/login');
        } else {
          this.$showError(this, err);
          // this.showMessage('Error occurred. Please refresh page.', 'error');
        }
      });

    // admin has all the roles, so make this as master list of roles
    // this.allRoles = this.accountUser.Roles;

    // if (account is level has automation feature) {
    //   this.allRoles = this.allRoles.filter((v) => v !== 'Automation_v2')
    // }
    Promise.all([
      this.getCurrentUser(this.user),
      this.getAllRoles()])
      .then((res) => {
        this.tempCurrUser = _.cloneDeep(res[0]);

        this.allRoles = this.getAllModules;
        // eslint-disable-next-line max-len
        this.tempCurrUser.AllowedSubAccounts = this.tempCurrUser.AllowedSubAccounts.map(subAccount => subAccount.SubAccountId);
        this.isSelf = this.accountUser.UserId === this.tempCurrUser.UserId;

        return this.getSmsReportExportConfig({ uId: this.tempCurrUser.UserId });
      })
      .then((res2) => {
        this.selectedUserConfig = res2;
      })
      .catch((err) => {
        if (err && err.message === 'Unauthorized') {
          localStorage.removeItem('WWW-Authenticate');
          // this.$router.push({ path: '/' });
          // singleSpa.navigateToUrl('/login');
          this.$showError(this, err);
        } else {
          this.$showError(this, err);
          // this.showMessage('Error occurred. Please refresh page.', 'error');
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },

  methods: {
    ...mapActions({
      getAllRoles: 'usermanagement/getAllModules',
      getAuthUser: 'common/getAuthUser',
      getCurrentUser: 'usermanagement/getCurrentUser',
      updateUser: 'usermanagement/updateUser',
      getUsers: 'usermanagement/getUsers',
      deleteUser: 'usermanagement/deleteUser',
      resendInvitation: 'usermanagement/resendInvitation',
      updateSmsReportExportConfig: 'usermanagement/updateSmsReportExportConfig',
      getSmsReportExportConfig: 'usermanagement/getSmsReportExportConfig',
      removeSmsReportExportConfigByUserId: 'usermanagement/removeSmsReportExportConfigByUserId',
    }),

    applyCheckedRoles(roles) {
      this.tempCurrUser.Roles = roles;
    },

    prettifyLabel(string) {
      return utils.prettifyLabel(string);
    },

    concatModules(modules) {
      return utils.concatModules(modules);
    },

    // Resend Invite
    reinvite() {
      const { UserId, Login } = this.user;
      this.loading = true;
      this.resendInvitation({
        userId: UserId,
        data: {
          email: Login,
          invitedByUserId: this.user.InvitedBy_UserId,
        },
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: this.$t('user_management.permission[7]', { email: Login }),
          });
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: `Oops, Failed to sent invite to ${Login}.`,
          });
        }).finally(() => {
          this.loading = false;
        });
    },

    showMessage(message, type) {
      this.$message({
        showClose: true,
        message,
        type,
      });
    },

    // Save Changes
    saveChanges() {
      const {
        UserId: userId,
        Roles,
        AllowedSubAccounts: subAccounts,
        AccessLevel,
      } = this.tempCurrUser;

      if (!Roles.length) {
        this.$message.error(this.$t('user_management.permission[8]'));
        return;
      }

      this.$confirm(
        this.$t('confirmations.save_changes'),
        `${this.$t('actions.update')} ${this.$t('column_labels.user')}`, {
          confirmButtonText: this.$t('actions.save'),
          cancelButtonText: this.$t('actions.cancel'),
          type: 'warning',
        },
      ).then(() => {
        // Save
        const formatSubAccounts = (AccessLevel === 'A' ? this.subAccounts : subAccounts).map(subAcc => ({
          SubAccountId: AccessLevel === 'A' ? subAcc.SubAccountId : subAcc,
        }));

        const limitRoles = (AccessLevel !== 'A'); // 0 is Admin, 1 is User
        const limitSubAccounts = (AccessLevel !== 'A');

        this.loading = true;

        // Update roles, subaccounts, and accesslevel
        this.updateUser({
          userId,
          roles: AccessLevel === 'A' ? this.modules : Roles,
          accessLevel: AccessLevel,
          subAccounts: formatSubAccounts,
          updateType: ['ROLES', 'SUBACCOUNTS', 'ACCESSLEVEL'],
        })
          .then(() => {
            this.$message({
              type: 'success',
              message: this.$t('success.updated', { value: this.user.Login }),
            });

            this.$emit('closeModal');

            this.getUsers(true);
          })
          .then(() => {})
          .catch((err) => {
            this.$showError(this, err);
          })
          .finally(() => {
            this.loading = false;
          });
      }).catch(() => {});
    },

    // Deactivate User
    deactivate() {
      this.$confirm(
        this.$t('confirmations.deactivate', { item: this.user.Login }),
        `${this.$t('actions.deactivate')} ${this.$t('column_labels.user')}`, {
          confirmButtonText: this.$t('actions.deactivate'),
          cancelButtonText: this.$t('actions.cancel'),
          type: 'warning',
        },
      ).then(() => {
        this.loading = true;
        this.updateUser({
          userId: this.user.UserId,
          updateType: ['BLOCK'],
          userStatus: 'B',
        }).then(() => {
          this.updateSmsReportExportConfig({ uID: this.user.UserId, emptyRunAt: 1 });

          this.$message({
            type: 'success',
            message: this.$t('success.deactivated', { value: this.user.Login }),
          });

          this.$emit('closeModal');

          this.getUsers(true);
        }).catch(() => {
          this.$message.error(`Oops, Failed to deactivate ${this.user.Login}`);
        })
          .finally(() => {
            this.loading = false;
          });
      }).catch(() => {});
    },

    // Activate User
    activate() {
      const {
        UserId: userId,
        Roles,
        AllowedSubAccounts: subAccounts,
        AccessLevel,
      } = this.tempCurrUser;

      if (!Roles.length) {
        this.$message.error(this.$t('user_management.permission[8]'));
        return;
      }

      this.$confirm(
        this.$t('confirmations.reactivate', { item: this.user.Login }),
        `${this.$t('actions.reactivate')} ${this.$t('column_labels.user')}`, {
          confirmButtonText: this.$t('actions.confirm'),
          cancelButtonText: this.$t('actions.cancel'),
          type: 'warning',
        },
      ).then(() => {
        // Re-activate
        const formatSubAccounts = subAccounts.map(subAcc => ({
          SubAccountId: subAcc,
        }));

        const limitRoles = (AccessLevel !== 'A'); // 0 is Admin, 1 is User
        const limitSubAccounts = (AccessLevel !== 'A');

        // Update user status
        this.loading = true;

        this.updateUser({
          userId,
          userStatus: 'A',
          roles: Roles,
          accessLevel: AccessLevel,
          subAccounts: formatSubAccounts,
          updateType: ['UNBLOCK', 'ACCESSLEVEL', 'ROLES', 'SUBACCOUNTS'],
        })
          .then(() => {
            // add guard when reports config API returns empty
            if (this.selectedUserConfig) {
              const { Frequency: frequency, PreferredDay: preferredDay } = this.selectedUserConfig;
              if (frequency && preferredDay) {
                this.updateSmsReportExportConfig({
                  frequency,
                  preferredDay,
                  uID: this.user.UserId,
                });
              }
            }

            this.$message({
              type: 'success',
              message: this.$t('success.activated', { value: this.user.Login }),
            });

            this.$emit('closeModal');

            this.getUsers(true);
          })
          .catch(() => {
            this.$message.error(`Oops, Failed to activate ${this.user.Login}`);
          })
          .finally(() => {
            this.loading = false;
          });
      }).catch(() => {});
    },

    // Remove User
    removeUser() {
      const { Login, UserId } = this.user;
      this.$confirm(
        this.$t('confirmations.remove', { item: this.user.Login }),
        `${this.$t('actions.remove')} ${this.$t('column_labels.user')}`, {
          confirmButtonText: this.$t('actions.remove'),
          cancelButtonText: this.$t('actions.cancel'),
          type: 'warning',
        },
      ).then(() => {
        this.loading = true;

        this.deleteUser(UserId)
          .then(() => {
            this.clearFilters();
            this.$emit('closeModal');
            this.$message({
              message: this.$t('success.removed', { value: Login }),
              type: 'success',
            });

            this.removeSmsReportExportConfigByUserId({ uID: UserId });

            this.getUsers(true);
          })
          .catch(() => {
            this.$message.error(`Oops, Failed to remove ${Login}`);
          }).finally(() => {
            this.loading = false;
          });
      }).catch(() => {});
    },

    changeSlide(n) {
      this.slide = Number(n);
    },

    closeParentModal() {
      this.$emit('closeModal');
    },
  },
};
