import { Promise } from 'es6-promise';
import http from '../../../../utils/http';

export default {
  async getUsers({ commit }, isReset = true) {
    try {
      if (isReset) {
        commit('RESET_FILTERS');
      }

      commit('SET_LOADING', true);
      const { data } = await http.v1.get('users?rolesVersion=2');
      commit('SET_USERS', data);
      commit('SET_FILTERED_USERS');
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getAllModules({ commit }) {
    try {
      const { data } = await http.v1.get('users/roles?rolesVersion=2');
      commit('SET_ALL_MODULES', data);
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async getSubAccounts({ commit }, payload) {
    try {
      const { data } = await http.v1.get(`accounts/${payload.AccountUid}/sub-accounts`);
      commit('SET_SUB_ACCOUNTS', data[0]);
      return data[0];
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async getCurrentUser({ commit }, payload) {
    try {
      commit('SET_CURRENT_USER', {
        ...payload,
        NewRoles: null,
      });

      const { data } = await http.v1.get(`users/${payload.UserId}/roles?rolesVersion=2`);

      commit('SET_CURRENT_USER', {
        ...payload,
        ...data,
      });

      return {
        ...payload,
        ...data,
      };
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async updateUser({ commit }, payload) {
    try {
      const { data } = await http.v1.put(`portal-users/${payload.userId}`, {
        ...payload,
        rolesVersion: 2,
      });
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async inviteUsers({ commit }, payload) {
    try {
      const { data } = await http.v1.post(`users/${payload.UserId}/invites?ver=v3&admin_limit=1`, payload.Body);
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async resendInvitation({ commit }, payload) {
    try {
      const { data } = await http.v1.post(`users/${payload.userId}/resend-invite?ver=v3&admin_limit=1`, {
        ...payload.data,
      });
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async deleteUser({ commit, dispatch }, payload) {
    try {
      const { data } = await http.v1.delete(`users/${payload}`);
      await dispatch('getUsers');
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async updateSmsReportExportConfig({ commit }, payload) {
    try {
      const { data } = await http.v1.put('reports/traffic/config', {
        ...payload,
      });
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async getSmsReportExportConfig({ commit }, { uId }) {
    try {
      const { data } = await http.v1.get(`reports/traffic/config/${uId}`);
      return data && data.length && data[0];
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async removeSmsReportExportConfigByUserId({ commit }, { uID }) {
    try {
      const { data } = await http.v1.delete(`reports/traffic/config/${uID}`);
      return data[0];
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async updateAccountConfig({ commit }, { accountUid, config }) {
    try {
      const response = await http.v2.put(`account/${accountUid}/setting`, config);
      return response;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },
};
