//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import moment from 'moment';
import DOMPurify from 'dompurify';

import {
  mapGetters,
} from 'vuex';

import highlightMixin from '../mixins/highlight';
import timeFormatter from '../mixins/timeFormatter';


export default {
  name: 'UsersTable',

  mixins: [
    highlightMixin,
    timeFormatter,
  ],

  props: {
    users: {
      type: Array,
      default: () => [],
    },

    filteredUsers: {
      type: Array,
      default: () => [],
    },

    paginated: {
      type: Array,
      default: () => [],
    },

    pageNo: {
      type: Number,
      default: 1,
    },

    pageSize: {
      type: Number,
      default: 10,
    },

    filters: {
      type: Object,
      default: () => ({}),
    },

    search: {
      type: String,
      default: '',
    },

    getStatus: {
      type: Function,
      default: () => {},
    },

    get2faStatus: {
      type: Function,
      default: () => {},
    },

    auth: {
      type: Object,
      default: () => ({}),
    },

    cannotView: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    openModal: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters('usermanagement', [
      'getPageNo',
    ]),
    ...mapGetters('common', ['getTz']),

    lastPage() {
      return Math.ceil(this.total / parseInt(this.pageSize, 10));
    },

    total() {
      return this.filteredUsers.length;
    },
  },

  methods: {
    capitalizeFirstLetter(string) {
      return `${string[0].toUpperCase()}${string.slice(1).toLowerCase()}`;
    },

    getUserName(row) {
      if (row.Firstname && row.Lastname) {
        return `${this.capitalizeFirstLetter(row.Firstname)} ${this.capitalizeFirstLetter(row.Lastname)}`;
      }

      if (row.Firstname) {
        return this.capitalizeFirstLetter(row.Firstname);
      }

      return '';
    },

    handleSizeChange(size) {
      this.$emit('set-page-no', 1);
      this.$emit('set-page-size', Number(size));
      this.$emit('set-filtered-users');
    },

    openUserModal(user) {
      if (!Object.keys(this.auth).length) return;
      this.$emit('open-user-modal', user);
    },

    openModal(modal) {
      this.$emit('open-modal', modal);
    },

    handleSelectPage(pn) {
      this.$emit('set-page-no', Number(pn));
      this.$emit('set-filtered-users');
    },

    handlePaginationChange(action) {
      let page = this.pageNo;
      if (action === 'next') {
        page += 1;
      }

      if (action === 'prev' && page) {
        page -= 1;
      }

      this.$emit('set-page-no', Number(page));
      this.$emit('set-filtered-users');
    },

    domPurify(content) {
      return DOMPurify.sanitize(content, {
        ALLOWED_TAGS: ['span', 'b', 'strong'],
        ALLOWED_ATTR: ['style', 'class'],
      });
    },

    canEdit(row) {
      return this.auth && (this.auth.AccessLevel === 'A' || (this.auth.AccessLevel === 'U' && this.auth.Roles.includes('UserManagement.ManageUsers_v2') && row.AccessLevel !== 'A'));
    },

    onCellMouseEnter(row) {
      document.querySelector(`.action-${row.UserId}`).classList.remove('hidden');
      document.querySelector(`.action-${row.UserId}`).classList.add('block');
    },

    onCellMouseLeave(row) {
      document.querySelector(`.action-${row.UserId}`).classList.remove('block');
      document.querySelector(`.action-${row.UserId}`).classList.add('hidden');
    },
  },
};
