<template>
  <div class="overflow-hidden">
    <div
      class="slider"
    >
      <!-- Invite Agents -->
      <div
        v-if="slide === 1"
        class="w-full"
      >
        <div
          slot="title"
          class="text-2xl"
        >
          <p class="text-lg text-black">
            {{ $t('user_management.invite_users[0]') }}
          </p>
          <p class="mt-2 text-sm text-grey">
            {{ $t('user_management.invite_users[1]') }}
          </p>
        </div>
        <div class="mt-8">
          <!-- Email Input -->
          <el-select
            v-model="emailsValue"
            class="w-full text-base"
            multiple
            filterable
            allow-create
            :placeholder="$t('fields.email')"
            remote
            default-first-option
            size="small"
            @change="validateEmail"
          />
          <span
            v-show="invalid.email.message"
            class="mt-2 text-xs text-red-light block whitespace-normal"
          >
            {{ invalid.email.message }}
          </span>

          <!-- Buttons -->
          <div class="mt-16 flex justify-end">
            <el-button
              class="mr-2"
              size="small"
              @click="closeParentModal"
            >
              {{ $t('actions.cancel') }}
            </el-button>
            <el-button
              type="primary"
              :disabled="invalid.email.err"
              size="small"
              @click="createInvite"
            >
              {{ $t('user_management.invite_users[2]') }}
            </el-button>
          </div>
        </div>
      </div>

      <!-- Permission -->
      <div
        v-else-if="slide === 2"
        class="w-full"
      >
        <div
          slot="title"
          class="text-2xl flex"
        >
          <span>
            <i
              v-if="!loading"
              class="el-icon-arrow-left text-lg mr-2 cursor-pointer hover:text-blue align-top"
              @click="changeSlide(1)"
            />
            <i
              v-else
              class="el-icon-arrow-left text-lg mr-2 text-grey-dark align-top"
            />
          </span>
          <div>
            <p class="text-lg text-black">
              {{ $t('user_management.invite_users[2]') }}s
            </p>
            <p class="mt-2 text-sm text-grey">
              {{ $t('user_management.invite_users[3]') }}
            </p>
          </div>
        </div>

        <div class="mt-8">
          <div class="flex border-solid border-b border-grey-light pr-8">
            <div class="w-1/3 p-3">
              <span class="text-grey text-xs">{{ $t('column_labels.user') }}</span>
            </div>
            <div class="w-1/3 p-3">
              <span class="text-grey text-xs">{{ $t('column_labels.modules') }}</span>
            </div>
            <div
              class="w-1/3 p-3"
            >
              <span class="text-grey text-xs">{{ $t('fields.subaccounts') }}</span>
            </div>
          </div>

          <!-- Permission Table -->
          <ul
            v-if="invitees.length"
            class="list-reset"
          >
            <li
              v-for="usr in invitees"
              :key="usr.id"
              :class="{ ...inviteUserClass }"
            >
              <div class="flex inline-flex flex-col w-full">
                <div class="flex">
                  <!-- Email -->
                  <div class="w-1/3 p-3">
                    <span class="mt-2 text-xs truncate block">{{ usr.Login }}</span>
                  </div>

                  <!-- Modules -->
                  <div class="w-1/3 p-3">
                    <el-select
                      v-model="usr.AccessLevel"
                      :placeholder="$t('user_management.invite_users[4]')"
                      class="mr-2 w-full"
                      :disabled="!usr.AccessLevel"
                      size="small"
                      @change="replaceRoles(usr)"
                    >
                      <el-option
                        v-for="option in accessLevels"
                        :key="option.value"
                        :label="option.label"
                        :value="option.value"
                        :disabled="option.value === 'A' && user.AccessLevel === 'U'"
                      />
                    </el-select>
                    <div
                      v-if="usr.AccessLevel === 'U'"
                      class="mt-2"
                    >
                      <div class="mt-4">
                        <div class="mt-2">
                          <div>
                            <a
                              class="text-blue text-xs no-underline
                              hover:text-blue-light inline-block cursor-pointer"
                              @click="setUserModule(usr.Login)"
                            >
                              {{ $t('user_management.permission[1]') }}
                              <i class="el-icon-arrow-right" />
                            </a>
                          </div>

                          <div
                            v-if="usr.Roles.length"
                            class="block mt-4"
                          >
                            <el-tag
                              class="mb-1 mr-2"
                              type="info"
                              size="small"
                            >
                              {{ prettifyLabel(usr.Roles[0].trim()) }}
                            </el-tag>
                            <el-tooltip
                              v-if="usr.Roles.length-1 > 0"
                              placement="right"
                            >
                              <div
                                slot="content"
                                class="w-48"
                              >
                                {{ concatModules(usr.Roles.slice(1)) }}
                              </div>
                              <el-tag
                                class="cursor-pointer mb-1 mr-2"
                                type="info"
                                size="small"
                              >
                                {{ `+${usr.Roles.length-1} more` }}
                              </el-tag>
                            </el-tooltip>
                          </div>
                          <div
                            v-else
                            class="mt-2"
                          >
                            <span class="text-xs text-red-light">{{ $t('user_management.permission[3]') }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <!-- Subaccounts -->
                  <div
                    v-show="usr.AccessLevel === 'U'"
                    class="w-1/3 p-3 subaccount-field"
                  >
                    <el-select
                      v-model="usr.subAccounts"
                      :placeholder="$t('user_management.invite_users[5]')"
                      multiple
                      collapse-tags
                      class="mr-2 w-full block"
                      :disabled="usr.AccessLevel !== 'U'"
                      size="small"
                    >
                      <el-option-group :label="$t('user_management.invite_users[6]')">
                        <el-option
                          v-for="option in subAccounts"
                          :key="option.SubAccountId"
                          :label="option.SubAccountId"
                          :value="option.SubAccountId"
                        >
                          <span class="w-full truncate pr-5">{{ option.SubAccountId }}</span>
                        </el-option>
                      </el-option-group>
                    </el-select>
                  </div>
                </div>
              </div>

              <el-tooltip
                placement="bottom"
                :content="$t('actions.remove')"
              >
                <div
                  class="h-full p-2 flex
                    justify-end items-center
                    text-grey-dark text-grey-dark
                    hover:text-red cursor-pointer
                    rounded hover:border-red"
                  @click="remove(usr.Login)"
                >
                  <i class="el-icon-delete" />
                </div>
              </el-tooltip>
            </li>
          </ul>

          <p
            v-if="hasNoModules()"
            class="text-right my-4 text-xs text-red-light"
          >
            {{ $t('user_management.invite_users[7]') }}
          </p>

          <p
            v-if="hasAdminsReached()"
            class="text-right my-4 text-xs text-red-light"
          >
            {{ $t('user_management.invite_users[8]') }}
          </p>

          <!-- Buttons -->
          <div class="mt-5 flex justify-end">
            <el-button
              class="mr-2"
              :disabled="loading"
              size="small"
              @click="closeParentModal"
            >
              {{ $t('actions.cancel') }}
            </el-button>
            <el-button
              type="primary"
              :disabled="hasNoModules() || hasAdminsReached()"
              :loading="loading"
              size="small"
              @click="sendInvite"
            >
              {{ $t('user_management.invite_users[9]') }}
            </el-button>
          </div>
        </div>
      </div>

      <div
        v-else-if="slide === 3"
        class="w-full"
      >
        <ModuleList
          v-if="selectedUser.AccessLevel === 'U'"
          :name="selectedUser.Login"
          :all-roles="modules"
          :user-roles="selectedUser.Roles"
          :account-user="auth"
          :prettify-label="prettifyLabel"
          :apply="applyCheckedRoles"
          :is-invite="Boolean(true)"
          @backEvent="changeSlide(2)"
          @closeModal="closeParentModal"
        />
      </div>

      <!-- Result Slide -->
      <div
        v-else-if="slide === 4"
        class="w-full"
      >
        <div
          slot="title"
          class="flex"
        >
          <div>
            <p class="text-base text-black">
              {{ $t('user_management.invite_users[10]') }}
            </p>
          </div>
        </div>

        <div
          v-if="resultData.success.length"
          class="mt-8"
        >
          <div class="mb-5">
            <p class="text-sm text-black">
              <i class="el-icon-check mr-1 text-green" />
              {{ $t('user_management.invite_users[11]') }}
            </p>
            <p class="mt-2 text-grey-dark text-sm">
              {{ $t('user_management.invite_users[12]') }}
            </p>
            <ul class="list-reset mt-5 inline-flex flex-wrap">
              <li
                v-for="(result, i) in resultData.success"
                :key="i"
                class="inline-block"
              >
                <span
                  class="mr-2 mb-2 p-2
                  text-xs text-blue inline-flex items-center justify-center bg-blue-lightest
                  border-blue border-solid border rounded-sm"
                >
                  <span>{{ result.user.Login }}</span>
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div
          v-if="resultData.fail.length || resultData.limitFail.length"
          class="mt-8"
        >
          <div class="mb-5">
            <p class="text-sm text-black">
              <i class="el-icon-close mr-1 text-red" />
              {{ $t('user_management.invite_users[13]') }}
            </p>
            <div
              v-if="resultData.limitFail.length"
              class="mt-3"
            >
              <p class="text-grey-dark text-sm">
                {{ $t('user_management.invite_users[14]') }} <i>{{ $t('user_management.invite_users[15]') }} {{ resultData.limitFail[0].maxAdmins || 4 }}</i>
              </p>
              <ul class="list-reset mt-5 inline-flex flex-wrap">
                <li
                  v-for="(result, i) in resultData.limitFail"
                  :key="i"
                  class="inline-block"
                >
                  <span
                    class="mr-2 mb-2 p-2 text-xs
                  text-blue inline-flex items-center
                  justify-center bg-blue-lightest
                  border-blue border-solid border rounded-sm"
                  >
                    <span>{{ result.user.Login }}</span>
                  </span>
                </li>
              </ul>
            </div>
            <div
              v-if="resultData.fail.length"
              class="mt-3"
            >
              <p class="text-grey-dark text-sm">
                {{ $t('user_management.invite_users[16]') }}
              </p>
              <ul class="list-reset mt-5 inline-flex flex-wrap">
                <li
                  v-for="(result, i) in resultData.fail"
                  :key="i"
                  class="inline-block"
                >
                  <span
                    class="mr-2 mb-2 p-2 text-xs
                  text-blue inline-flex items-center
                  justify-center bg-blue-lightest
                  border-blue border-solid border rounded-sm"
                  >
                    <span>{{ result.user.Login }}</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div class="mt-5 flex justify-end">
            <el-button
              size="small"
              class="mr-2"
              @click="closeParentModal"
            >
              {{ $t('actions.close') }}
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="() => { changeSlide(1); reset(); }"
            >
              {{ $t('user_management.invite_users[17]') }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
// import singleSpa from 'single-spa';

import ModuleList from './ModuleList.vue';
import utils from '../common/helper';

export default {
  name: 'InviteUser',

  components: {
    ModuleList,
  },

  props: {
    closed: {
      type: Boolean,
      default: true,
    },

    auth: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      user: {},
      slide: 1,
      adminCountLimit: 4,
      loading: false,
      selectedUser: {},
      invitees: [],
      emailsValue: [],
      inviteUserClass: {
        'items-end': true,
        flex: true,
        relative: true,
        'permission-item': true,
        'w-full': true,
        'border-solid': true,
        'border-b': true,
        'border-grey-light': true,
        'text-black': true,
        'hover:bg-grey-lighter': true,
      },
      invalid: {
        email: {
          err: false,
          message: '',
        },
      },
      accessLevels: [
        {
          value: 'A',
          label: 'Admin',
        },
        {
          value: 'U',
          label: 'User',
        },
      ],
      resultData: {
        fail: [],
        limitFail: [],
        success: [],
      },
    };
  },

  computed: {
    ...mapState(
      'usermanagement', ['subAccounts'],
    ),

    ...mapGetters('usermanagement', ['getAllModules']),
  },

  watch: {
    // Reset values to empty
    closed(closedVal) {
      if (closedVal) {
        this.changeSlide(1);
        this.reset();
      }
    },
  },

  created() {
    // const { Roles } = this.auth;

    this.user = this.auth;

    this.reset();

    // since admin has all the roles
    // this.modules = Roles;

    // if (account is level has automation feature) {
    //   this.modules = this.modules.filter((v) => v !== 'Automation_v2')
    // }

    // check if user is allowed to invite user
    this.getAuthUser()
      .then((response) => {
        if (!response.data.Roles.some(v => v.match(/UserManagement/i))
          && response.data.AccessLevel === 'U') {
          throw new Error('Unauthorized');
        }

        return this.getAllRoles();
      }).then(() => {
        this.modules = this.getAllModules;
      })
      .catch((err) => {
        if (err && err.message === 'Unauthorized') {
          localStorage.removeItem('WWW-Authenticate');
          localStorage.removeItem('cpv3Impersonate');
          localStorage.removeItem('CPV3_User');
          localStorage.removeItem('passwordResetSkipped');
          localStorage.removeItem('user_country');
          sessionStorage.removeItem('sessionHideSuggestEnableTwoFa');
          // singleSpa.navigateToUrl('/login');
          this.$showError(this, err);
        } else {
          this.$showError(this, err);
        }
      });
  },

  methods: {
    ...mapActions({
      getAuthUser: 'common/getAuthUser',
      getUsers: 'usermanagement/getUsers',
      inviteUsers: 'usermanagement/inviteUsers',
      getAllRoles: 'usermanagement/getAllModules',
    }),

    hasNoModules() {
      return this.invitees.some(v => !v.Roles.length);
    },

    hasAdminsReached() {
      const adminCount = this.invitees.filter(v => v.AccessLevel === 'A').length;
      return adminCount >= this.adminCountLimit;
    },

    prettifyLabel(string) {
      return utils.prettifyLabel(string);
    },

    concatModules(modules) {
      return utils.concatModules(modules);
    },

    showMessage(message, type) {
      this.$message({
        showClose: true,
        message,
        type,
      });
    },

    applyCheckedRoles(modules, login) {
      if (login) {
        this.invitees = this.invitees.slice(0).map((v) => {
          if (v.Login === login) {
            // eslint-disable-next-line no-param-reassign
            v.Roles = modules;
          }

          return v;
        });
      }
    },

    // Remove email
    remove(email) {
      const newInvitees = this.invitees.filter(invitee => invitee.Login !== email);
      this.invitees = newInvitees;

      if (!this.invitees.length) {
        this.changeSlide(1);
      }
    },

    // Clear all fields
    reset() {
      this.resultData.fail = [];
      this.resultData.limitFail = [];
      this.resultData.success = [];

      this.changeSlide(1);
      this.emailsValue = [];
      this.invitees = [];
      this.validateEmail();
    },

    // Create User Invite Object List
    createInvite() {
      const defaultSubAccount = this.subAccounts.map(subacc => subacc.SubAccountId);
      const autoCheckCAShowMB = this.user.Roles && this.user.Roles.includes('SMS.ShowMessageBody_v2');

      let roles = [];

      // auto check CA.ShowMessageBody, if CA-ShowLogMessage is in Beta features
      if (autoCheckCAShowMB) {
        roles = ['CA.ShowMessageBody_v2'];
      }

      const inviteObj = this.emailsValue.map((email, i) => ({
        id: i,
        Login: email,
        AccessLevel: 'U',
        Roles: roles,
        subAccounts: defaultSubAccount,
      }));

      this.invitees = inviteObj;
      this.changeSlide(2);
    },

    replaceRoles(user) {
      const autoCheckCAShowMB = this.user.Roles && this.user.Roles.includes('CA-ShowLogMessage');

      this.invitees = this.invitees.slice(0).map((v) => {
        if (v.Login === user.Login) {
          let roles = [];

          if (autoCheckCAShowMB && user.AccessLevel === 'U') {
            roles = ['CA.ShowMessageBody_v2'];
          }

          // eslint-disable-next-line no-param-reassign
          v.Roles = user.AccessLevel === 'A' ? this.modules : roles;
        }

        return v;
      });
    },

    // Set selected module
    setUserModule(email) {
      this.selectedUser = this.invitees.find(user => user.Login === email);
      this.changeSlide(3);
    },

    // Custom modal card slider
    changeSlide(n) {
      this.slide = Number(n);
    },

    closeParentModal() {
      this.reset();
      this.$emit('closeModal');
    },

    // Check if email is empty or invalid; disable button if so
    validateEmail() {
      const invalidEmail = this.emailsValue.filter(email => !email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/));

      const emailValid = this.invalid.email;

      if (!this.emailsValue.length) {
        emailValid.err = true;
        emailValid.message = null;
      } else if (invalidEmail.length) {
        emailValid.err = true;
        emailValid.message = `${this.$t('user_management.invite_users[18]')} ${invalidEmail}`;
      } else {
        emailValid.err = false;
        emailValid.message = null;
      }
    },

    sendInvite() {
      // Format structure to be passed in API
      const invitedUsers = this.invitees.map((user) => {
        const {
          Login: email,
          AccessLevel: accessLevel,
          Roles: roles,
        } = user;

        const subAccounts = user.subAccounts.map(SubAccountId => ({ SubAccountId }));

        return {
          email,
          invitee: email,
          accessLevel,
          roles,
          subAccounts,
        };
      });

      this.loading = true;
      const payload = {
        UserId: this.user.UserId,
        Body: invitedUsers,
      };

      this.inviteUsers(payload)
        .then((data) => {
          data.forEach((v) => {
            if (v.error) {
              if (v.code && v.code === 'MAX_ADMINS') {
                this.resultData.limitFail.push(v);
              } else {
                this.resultData.fail.push(v);
              }
            } else {
              this.resultData.success.push(v);
              this.getUsers(true);
            }
          });

          this.changeSlide(4);
        })
        .catch((err) => {
          this.closeParentModal();
          this.$showError(this, err);
        }).finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.invite-dropdown {
  display: none;
}

.permission-item {
  transition: background-color 0.5s ease;
}

.el-dialog__body {
  padding-top: 0;
}

.subaccount-field {
  .el-tag {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 10px;
  }
}
</style>
