//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapGetters } from 'vuex';
// import singleSpa from 'single-spa';

import ModuleList from './ModuleList.vue';
import utils from '../common/helper';

export default {
  name: 'InviteUser',

  components: {
    ModuleList,
  },

  props: {
    closed: {
      type: Boolean,
      default: true,
    },

    auth: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      user: {},
      slide: 1,
      adminCountLimit: 4,
      loading: false,
      selectedUser: {},
      invitees: [],
      emailsValue: [],
      inviteUserClass: {
        'items-end': true,
        flex: true,
        relative: true,
        'permission-item': true,
        'w-full': true,
        'border-solid': true,
        'border-b': true,
        'border-grey-light': true,
        'text-black': true,
        'hover:bg-grey-lighter': true,
      },
      invalid: {
        email: {
          err: false,
          message: '',
        },
      },
      accessLevels: [
        {
          value: 'A',
          label: 'Admin',
        },
        {
          value: 'U',
          label: 'User',
        },
      ],
      resultData: {
        fail: [],
        limitFail: [],
        success: [],
      },
    };
  },

  computed: {
    ...mapState(
      'usermanagement', ['subAccounts'],
    ),

    ...mapGetters('usermanagement', ['getAllModules']),
  },

  watch: {
    // Reset values to empty
    closed(closedVal) {
      if (closedVal) {
        this.changeSlide(1);
        this.reset();
      }
    },
  },

  created() {
    // const { Roles } = this.auth;

    this.user = this.auth;

    this.reset();

    // since admin has all the roles
    // this.modules = Roles;

    // if (account is level has automation feature) {
    //   this.modules = this.modules.filter((v) => v !== 'Automation_v2')
    // }

    // check if user is allowed to invite user
    this.getAuthUser()
      .then((response) => {
        if (!response.data.Roles.some(v => v.match(/UserManagement/i))
          && response.data.AccessLevel === 'U') {
          throw new Error('Unauthorized');
        }

        return this.getAllRoles();
      }).then(() => {
        this.modules = this.getAllModules;
      })
      .catch((err) => {
        if (err && err.message === 'Unauthorized') {
          localStorage.removeItem('WWW-Authenticate');
          localStorage.removeItem('cpv3Impersonate');
          localStorage.removeItem('CPV3_User');
          localStorage.removeItem('passwordResetSkipped');
          localStorage.removeItem('user_country');
          sessionStorage.removeItem('sessionHideSuggestEnableTwoFa');
          // singleSpa.navigateToUrl('/login');
          this.$showError(this, err);
        } else {
          this.$showError(this, err);
        }
      });
  },

  methods: {
    ...mapActions({
      getAuthUser: 'common/getAuthUser',
      getUsers: 'usermanagement/getUsers',
      inviteUsers: 'usermanagement/inviteUsers',
      getAllRoles: 'usermanagement/getAllModules',
    }),

    hasNoModules() {
      return this.invitees.some(v => !v.Roles.length);
    },

    hasAdminsReached() {
      const adminCount = this.invitees.filter(v => v.AccessLevel === 'A').length;
      return adminCount >= this.adminCountLimit;
    },

    prettifyLabel(string) {
      return utils.prettifyLabel(string);
    },

    concatModules(modules) {
      return utils.concatModules(modules);
    },

    showMessage(message, type) {
      this.$message({
        showClose: true,
        message,
        type,
      });
    },

    applyCheckedRoles(modules, login) {
      if (login) {
        this.invitees = this.invitees.slice(0).map((v) => {
          if (v.Login === login) {
            // eslint-disable-next-line no-param-reassign
            v.Roles = modules;
          }

          return v;
        });
      }
    },

    // Remove email
    remove(email) {
      const newInvitees = this.invitees.filter(invitee => invitee.Login !== email);
      this.invitees = newInvitees;

      if (!this.invitees.length) {
        this.changeSlide(1);
      }
    },

    // Clear all fields
    reset() {
      this.resultData.fail = [];
      this.resultData.limitFail = [];
      this.resultData.success = [];

      this.changeSlide(1);
      this.emailsValue = [];
      this.invitees = [];
      this.validateEmail();
    },

    // Create User Invite Object List
    createInvite() {
      const defaultSubAccount = this.subAccounts.map(subacc => subacc.SubAccountId);
      const autoCheckCAShowMB = this.user.Roles && this.user.Roles.includes('SMS.ShowMessageBody_v2');

      let roles = [];

      // auto check CA.ShowMessageBody, if CA-ShowLogMessage is in Beta features
      if (autoCheckCAShowMB) {
        roles = ['CA.ShowMessageBody_v2'];
      }

      const inviteObj = this.emailsValue.map((email, i) => ({
        id: i,
        Login: email,
        AccessLevel: 'U',
        Roles: roles,
        subAccounts: defaultSubAccount,
      }));

      this.invitees = inviteObj;
      this.changeSlide(2);
    },

    replaceRoles(user) {
      const autoCheckCAShowMB = this.user.Roles && this.user.Roles.includes('CA-ShowLogMessage');

      this.invitees = this.invitees.slice(0).map((v) => {
        if (v.Login === user.Login) {
          let roles = [];

          if (autoCheckCAShowMB && user.AccessLevel === 'U') {
            roles = ['CA.ShowMessageBody_v2'];
          }

          // eslint-disable-next-line no-param-reassign
          v.Roles = user.AccessLevel === 'A' ? this.modules : roles;
        }

        return v;
      });
    },

    // Set selected module
    setUserModule(email) {
      this.selectedUser = this.invitees.find(user => user.Login === email);
      this.changeSlide(3);
    },

    // Custom modal card slider
    changeSlide(n) {
      this.slide = Number(n);
    },

    closeParentModal() {
      this.reset();
      this.$emit('closeModal');
    },

    // Check if email is empty or invalid; disable button if so
    validateEmail() {
      const invalidEmail = this.emailsValue.filter(email => !email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/));

      const emailValid = this.invalid.email;

      if (!this.emailsValue.length) {
        emailValid.err = true;
        emailValid.message = null;
      } else if (invalidEmail.length) {
        emailValid.err = true;
        emailValid.message = `${this.$t('user_management.invite_users[18]')} ${invalidEmail}`;
      } else {
        emailValid.err = false;
        emailValid.message = null;
      }
    },

    sendInvite() {
      // Format structure to be passed in API
      const invitedUsers = this.invitees.map((user) => {
        const {
          Login: email,
          AccessLevel: accessLevel,
          Roles: roles,
        } = user;

        const subAccounts = user.subAccounts.map(SubAccountId => ({ SubAccountId }));

        return {
          email,
          invitee: email,
          accessLevel,
          roles,
          subAccounts,
        };
      });

      this.loading = true;
      const payload = {
        UserId: this.user.UserId,
        Body: invitedUsers,
      };

      this.inviteUsers(payload)
        .then((data) => {
          data.forEach((v) => {
            if (v.error) {
              if (v.code && v.code === 'MAX_ADMINS') {
                this.resultData.limitFail.push(v);
              } else {
                this.resultData.fail.push(v);
              }
            } else {
              this.resultData.success.push(v);
              this.getUsers(true);
            }
          });

          this.changeSlide(4);
        })
        .catch((err) => {
          this.closeParentModal();
          this.$showError(this, err);
        }).finally(() => {
          this.loading = false;
        });
    },
  },
};
