import { Promise } from 'es6-promise';
import http from '../../../../utils/http';

export default {

  getCountries: async ({ commit }) => {
    try {
      const data = await http.v1.get('countries');
      commit('SET_COUNTRIES', data[0]);

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  getTimezones: async ({ commit }) => {
    try {
      const data = await http.v1.get('timezones');
      commit('SET_TIMEZONES', data[0]);
      return data[0];
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  setTimezones({ commit }, payload) {
    return new Promise((resolve) => {
      commit('SET_TIMEZONES', payload);
      resolve(payload);
    });
  },

  getSubAccounts: async ({ commit }, payload) => {
    try {
      const data = await http.v1.get(`accounts/${payload.AccountUid}/sub-accounts`);
      commit('SET_SUB_ACCOUNTS', data[0]);
      return data[0];
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  getAuthUser: async ({ commit }) => {
    try {
      const response = await http.v1.get('auth/user?rolesVersion=2');
      commit('SET_AUTH', response.data);
      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
};
