import actions from './actions';

export default {
  namespaced: true,

  state: {
    auth: {},
    countries: [],
    loggedInUser: undefined,
    accountCountries: [],
    timezones: [],
    subAccounts: [],
    savedPayments: [],
    balance: {},
    obScenarios: [],
    geoIP: {},
    getGeoCode: {},
    whitelistCountry: [],

    timeZoneName: null,
    timeZoneOffset: 0,
    timeZone: '(UTC-00)',
    isTimeZoneAllowed: false,
  },
  getters: {
    getSubAccount(state) {
      return state.subAccounts[0];
    },

    getTz: (st) => {
      const {
        timeZone,
        timeZoneName,
        timeZoneOffset,
      } = st;

      return {
        timeZone,
        timeZoneName,
        timeZoneOffset,
      };
    },
  },

  mutations: {
    SET_AUTH(st, payload) {
      st.auth = payload; // eslint-disable-line

      const {
        // BetaFeatures: betaFeatures,
        TimeZoneName,
        TimeZoneGMTOffset,
      } = payload.data;

      st.isTimeZoneAllowed = true; // eslint-disable-line

      // User timezone settings
      st.timeZoneName = TimeZoneName || 'UTC / Greenwich Mean Time'; // eslint-disable-line
      st.timeZoneOffset = TimeZoneGMTOffset ? (TimeZoneGMTOffset / 60) : 0; // eslint-disable-line
      let timeZone = '(UTC-00)';
      if (TimeZoneGMTOffset) {
        const offset = new Date(TimeZoneGMTOffset * 1000).toUTCString().split(' ')[4].replace(/:00$/, '');
        let prefix = '-';
        if (TimeZoneGMTOffset > 0) {
          prefix = '+';
        }
        timeZone = `(UTC${prefix}${offset})`;
      }
      st.timeZone = timeZone; // eslint-disable-line
    },

    SET_COUNTRIES(state, payload) {
      state.countries = payload; // eslint-disable-line no-param-reassign
    },

    BALANCE(state, payload) {
      state.balance = payload; // eslint-disable-line
    },

    ACCOUNT_COUNTRIES(state, payload) {
      state.accountCountries = payload; // eslint-disable-line
    },

    SET_TIMEZONES(state, payload) {
      const timezones = payload.reduce((acc, tz) => {
        const offset = new Date(tz.GMTOffset * 1000).toUTCString().split(' ')[4].replace(/:00$/, '');
        let prefix = '-';
        if (tz.GMTOffset > 0) {
          prefix = '+';
        }
        tz.Combined = `(UTC${prefix}${offset}): ${tz.TimeZoneName}`; // eslint-disable-line
        acc.push(tz);

        return acc;
      }, []);

      state.timezones = timezones; // eslint-disable-line no-param-reassign
    },

    LOGIN_USER(state, user) {
      state.loggedInUser = user; // eslint-disable-line
    },

    SET_SUB_ACCOUNTS(state, payload) {
      state.subAccounts = payload; // eslint-disable-line no-param-reassign
    },

    OB_SCENARIOS(state, payload) {
      state.obScenarios = payload; // eslint-disable-line no-param-reassign
    },

    SET_WHITELISTCOUNTRY(state, payload) {
      state.whitelistCountry = payload; // eslint-disable-line no-param-reassign
    },

    SET_GEOIP(state, payload) {
      state.geoIP = payload; // eslint-disable-line no-param-reassign
    },

    SET_GEOCODE(state, payload) {
      state.getGeoCode = payload; // eslint-disable-line no-param-reassign
    },
  },

  actions,
};
