import actions from './actions';

export default {
  namespaced: true,

  state: {
    config: undefined,
  },

  mutations: {
    SET_CONFIG(state, payload) {
      state.config = payload; //eslint-disable-line
    },
  },

  getters: {
    getConfig(state) {
      return state.config;
    },
  },

  actions,
};
